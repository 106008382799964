import React, { useState, useEffect } from "react";
import "./DateTimePicker.css";

const DateTimePicker = ({ mode, onApply, handleCloseModal }) => {
  const [date, setDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState("1:00");
  const [ampm, setAmpm] = useState("AM");
  const [visibleTimes, setVisibleTimes] = useState(["12:30", "1:00", "1:30"]);
  const [selectedDateTime, setSelectedDateTime] = useState({
    date: new Date(),
    time: "1:00",
    ampm: "AM",
  });

  const year = date.getFullYear();
  const month = date.getMonth();
  const daysInMonth = new Date(year, month + 1, 0).getDate();
  const firstDay = new Date(year, month, 1).getDay();

  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const times = Array.from({ length: 24 }, (_, index) => {
    const hour = Math.floor(index / 2) % 12 || 12;
    const minutes = index % 2 === 0 ? "00" : "30";
    return `${hour}:${minutes}`;
  });

  const updateVisibleTimes = (newTime) => {
    const currentIndex = times.indexOf(newTime);
    setVisibleTimes([
      times[currentIndex - 1] || times[times.length - 1],
      newTime,
      times[currentIndex + 1] || times[0],
    ]);
    setSelectedTime(newTime); // Set selectedTime when a new time is selected
    setSelectedDateTime((prev) => ({
      ...prev,
      time: newTime,
    }));
  };
  const handleAmpmChange = (newAmpm) => {
    setAmpm(newAmpm);
    setSelectedDateTime((prev) => ({
      ...prev,
      ampm: newAmpm,
    }));
  };

  const handlePrevMonth = () => {
    setDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() - 1);
      return newDate;
    });
  };

  const handleNextMonth = () => {
    setDate((prevDate) => {
      const newDate = new Date(prevDate);
      newDate.setMonth(newDate.getMonth() + 1);
      return newDate;
    });
  };

  const handleDayClick = (day) => {
    const selectedDate = new Date(year, month, day);
    if (selectedDate >= currentDate) {
      setSelectedDateTime((prev) => ({
        ...prev,
        date: selectedDate,
      }));
    }
  };

  const daysArray = [
    ...Array.from({ length: firstDay }, () => ""),
    ...Array.from({ length: daysInMonth }, (_, i) => i + 1),
  ];

  const handleApply = () => {
    const selectedDate = new Date(
      selectedDateTime.date.getFullYear(),
      selectedDateTime.date.getMonth(),
      selectedDateTime.date.getDate(),
      parseInt(selectedTime.split(":")[0]) + (ampm === "PM" ? 12 : 0),
      parseInt(selectedTime.split(":")[1])
    );

    if (selectedDate < minAllowedTime) {
      setWarning("Please select a time at least 30 minutes from current time!");
    } else {
      setWarning(""); // Clear warning if time is valid
      onApply(selectedDateTime);
      console.log("datetimepickedpage:", JSON.stringify(selectedDateTime));
    }
  };

  const dayLabels = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
  const [warning, setWarning] = useState("");
  const minAllowedTime = new Date(currentDate.getTime() + 30 * 60000);
  {
    warning && <p className="text-danger text-center">{warning}</p>;
  }

  const handleTimeUp = () => {
    const currentIndex = times.indexOf(selectedTime);
    const nextIndex = (currentIndex + 1) % times.length; // Move to next time
    updateVisibleTimes(times[nextIndex]); // Update the selected time
  };

  const handleTimeDown = () => {
    const currentIndex = times.indexOf(selectedTime);
    const prevIndex = (currentIndex - 1 + times.length) % times.length; // Move to previous time
    updateVisibleTimes(times[prevIndex]); // Update the selected time
  };

  return (
    <>
      <div>
        <div>
          <i className="fa-regular fa-calendar-check me-1 picker-icon"></i>
          <span>
            {mode === "pickup" ? "Select Pickup Date" : "Select Drop Date"}
          </span>
        </div>
        <div className="calender text-dark shadow-sm mb-4 mt-2">
          <div className="calender-header rounded-top d-flex justify-content-between align-items-center mt-3">
            <button
              className="calender-prev fw-bolder fs-6 py-2 px-3"
              onClick={handlePrevMonth}
            >
              &lt;
            </button>
            <h2 className="calender-month-year fw-bold fs-6 my-auto">
              {new Date(year, month).toLocaleString("default", {
                month: "long",
              })}
              &nbsp;{year}
            </h2>
            <button
              className="calender-next fw-bolder fs-6 py-2 px-3"
              onClick={handleNextMonth}
            >
              &gt;
            </button>
          </div>

          {/* Day labels */}
          <div
            className="calender-day-labels mt-1 d-grid"
            style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
          >
            {dayLabels.map((label, index) => (
              <div key={index} className="day-label mx-auto fw-bold">
                {label}
              </div>
            ))}
          </div>

          <div
            className="calender-days mt-3 d-grid pb-1 px-1"
            style={{ gridTemplateColumns: "repeat(7, 1fr)" }}
          >
            {daysArray.map((day, index) => {
              const isToday =
                day === currentDay &&
                month === currentMonth &&
                year === currentYear;
              const isSelected =
                day === selectedDateTime.date.getDate() &&
                month === selectedDateTime.date.getMonth() &&
                year === selectedDateTime.date.getFullYear();
              const isDisabled =
                new Date(year, month, day) < currentDate.setHours(0, 0, 0, 0);

              return (
                <div
                  key={index}
                  className={`day ${isToday ? "highlighted" : ""} ${
                    isSelected ? "selected" : ""
                  } ${isDisabled ? "disabled" : ""}`}
                  onClick={() => !isDisabled && day && handleDayClick(day)}
                >
                  {day}
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <i className="fa-regular fa-clock me-1 picker-icon"></i>
          <span>
            {mode === "pickup" ? "Select Pickup Time" : "Select Drop Time"}
          </span>
        </div>
        <div className="time-picker-container my-3 row">
          <div className="col-6 row m-0 p-0 d-flex justify-content-end">
            <ul className="time-list col-6">
              {visibleTimes.map((time) => (
                <li
                  key={time}
                  className={`time-list-item px-4 shadow-sm py-2 ${
                    time === selectedTime ? "active bold shadow-lg fw-6" : ""
                  }`}
                  onClick={() => updateVisibleTimes(time)}
                >
                  {time}
                </li>
              ))}
            </ul>
            <div className="col-6 d-flex align-items-center row">
              <span>
                <button
                  className="updownchevdatepicker updownchevdatepickerup shadow-sm"
                  onClick={handleTimeDown}
                >
                  <i class="fa-solid fa-chevron-up"></i>
                </button>
              </span>
              <span>
                <button
                  className="updownchevdatepicker updownchevdatepickerdown shadow-sm"
                  onClick={handleTimeUp}
                >
                  <i class="fa-solid fa-chevron-down"></i>
                </button>
              </span>
            </div>
          </div>
          <div className="ampm-selector col-6 align-items-start d-flex">
            <div className="row mx-5">
              <button
                className="ampm-button my-2 py-1"
                onClick={() => handleAmpmChange("AM")}
                disabled={ampm === "AM"}
              >
                AM
              </button>
              <button
                className="ampm-button my-2 py-1"
                onClick={() => handleAmpmChange("PM")}
                disabled={ampm === "PM"}
              >
                PM
              </button>
            </div>
          </div>
        </div>
      </div>
      {warning && <p className="text-danger text-center">{warning}</p>}
      <div className="row px-3">
        <button
          className="px-3 py-2 me-2 rounded col picker-modal-close border text-dark"
          onClick={handleCloseModal}
        >
          Close
        </button>
        <button
          onClick={handleApply}
          className="rounded shadow-sm px-3 py-2 col picker-modal-apply text-light fw-bold border"
        >
          Apply
        </button>
      </div>
    </>
  );
};

export default DateTimePicker;
