import React from "react";
import { FaWhatsapp, FaLinkedinIn, FaMailBulk, FaGit } from "react-icons/fa";

export default function DevInfo() {
  return (
    <>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh", backgroundColor: "#ffffff" }}
      >
        <div className="col-lg-6 text-end w-75">
          <span>
            <u>
              <i>Developed By:</i>
            </u>
          </span>
          <br />
          <span style={{ fontSize: "1em" }}>
            <i>Name:</i>&nbsp;
          </span>
          <span className="fs-1 fw-bold" style={{ color: "#ff6600" }}>
            Yashas D
          </span>
          <br />
          <span>Software Development Engg.</span>
          <br />
          <span>Mysuru 17.</span>
          <br />
          <hr />
          <span className="text-start d-flex">
            <a
              id="footer-icons"
              href="https://api.whatsapp.com/send/?phone=9964466622&text&type=phone_number&app_absent=0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaWhatsapp className="mx-2" />
            </a>
            <a
              id="footer-icons"
              href="https://www.linkedin.com/in/yashas-d-281aa4249?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="mx-2" />
            </a>
            <a
              id="footer-icons"
              href="mailto:yashasd9@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMailBulk className="mx-2" />
            </a>
            <a
              id="footer-icons"
              href="https://github.com/666gowda"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGit className="mx-2" />
            </a>
          </span>
          <br />
          <span className="fs-6">Contact:</span>
          <br />
          <span className="fs-4">
            <a href="https://github.com/666gowda">my-github</a>
          </span>
          <br />
          <span>
            <a
              style={{ textDecoration: "none" }}
              href="mailto:yashasd9@gmail.com"
            >
              &gt;&gt;&gt;&nbsp;yashasd9@gmail.com
            </a>
          </span>
        </div>
      </div>
    </>
  );
}
