import React, { useState, useEffect } from "react";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import HeroFooter from "../hero-footer/HeroFooter";
import FilterBooking from "./FilterBooking";
import BikeListings from "./BikeListings";
import EmptyListing from "./EmptyListing";
import { Toast, ToastContainer } from "react-bootstrap";

export default function BookingPage() {
  const [location, setLocation] = useState("setlocation");
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(null);
  const [duration, setDuration] = useState(null);
  const [showToast, setShowToast] = useState(false);

  const [sortByPrice, setSortByPrice] = useState(null);
  const [filterByTransmission, setFilterByTransmission] = useState(null);
  const [filterByFuelType, setFilterByFuelType] = useState(null);

  const handleFilterChange = ({ sort, transmission, fuel }) => {
    setSortByPrice(sort);
    setFilterByTransmission(transmission);
    setFilterByFuelType(fuel);
  };

  useEffect(() => {
    if (
      location !== "setlocation" &&
      selectedStartDateTime &&
      selectedEndDateTime
    ) {
      setShowToast(true);
    }
  }, [location, selectedStartDateTime, selectedEndDateTime]);

  const handleStartDateTimeChange = (dateTime) => {
    setSelectedStartDateTime(dateTime);
  };

  const handleEndDateTimeChange = (dateTime, calculatedDuration) => {
    setSelectedEndDateTime(dateTime);
    setDuration(calculatedDuration);
  };

  // Handlers for filters
  const handleSortByPriceChange = (sortOption) => {
    setSortByPrice(sortOption);
  };

  const handleFilterByTransmissionChange = (transmissionOption) => {
    setFilterByTransmission(transmissionOption);
  };

  const handleFilterByFuelTypeChange = (fuelOption) => {
    setFilterByFuelType(fuelOption);
  };

  // Convert date and time to dd-mm-yyyy hh:mm AM/PM format
  const formatDateTime = ({ date, time, ampm }) => {
    if (!date || !time || !ampm) return "";
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year} ${time} ${ampm}`;
  };

  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => setShowToast(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [showToast]);

  return (
    <div>
      <HeroNavBar
        showCenterNav={false}
        isBookingPage={true}
        isHeroLogoVisible={false}
        location={location}
        setLocation={setLocation}
        onFilterChange={handleFilterChange}
      />
      <FilterBooking
        onStartDateTimeChange={handleStartDateTimeChange}
        onEndDateTimeChange={handleEndDateTimeChange}
        onSortByPriceChange={handleSortByPriceChange}
        onFilterByTransmissionChange={handleFilterByTransmissionChange}
        onFilterByFuelTypeChange={handleFilterByFuelTypeChange}
      />
      {selectedStartDateTime &&
      selectedEndDateTime &&
      location !== "setlocation" ? (
        <BikeListings
          pickupDate={formatDateTime(selectedStartDateTime)}
          dropDate={formatDateTime(selectedEndDateTime)}
          branch={location}
          duration={duration}
          sortByPrice={sortByPrice} // Pass the sorting option
          filterByTransmission={filterByTransmission} // Pass the transmission filter
          filterByFuelType={filterByFuelType}
        />
      ) : (
        <EmptyListing />
      )}
      <HeroFooter />

      {/* Toast Notification */}
      <ToastContainer position="top-center" className="p-3">
        <Toast
          show={showToast}
          onClose={() => setShowToast(false)}
          delay={3000}
          autohide
          style={{ border: "1px solid #ff6600", borderRadius: "4px" }}
        >
          <Toast.Body>
            Showing results for&nbsp;
            <span style={{ color: "#ff6600", fontWeight: "600" }}>
              "{location}"
            </span>
          </Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
