import React, { useState, useEffect } from "react";
import "./RegisterAccount.css";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import loginleftimg from "../../assets/login-left-img.png";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import fullsizelogo from "../../assets/full-size-logo.png";
import { FaPlus, FaTrashAlt } from "react-icons/fa"; // Import plus and delete icons
import { Toast, ToastContainer } from "react-bootstrap";
import axios from "axios";

export default function RegisterAccount() {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91"); // Default country code
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false); // New state to track form validity

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    setProfilePicture(file);

    // Create a preview URL for the uploaded image
    if (file) {
      const previewURL = URL.createObjectURL(file);
      setProfilePicturePreview(previewURL);
    }
  };

  const handleDeleteProfilePicture = () => {
    setProfilePicture(null);
    setProfilePicturePreview(null);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid) {
      try {
        // Call the API to register the user
        await axios.post(
          "https://speedrent.in/v2Apis/production/login/register.php",
          new URLSearchParams({
            api_key: "Pavan@2024",
            number: phoneNumber,
            name: name,
            email: email,
          })
        );

        // Redirect to KYC step one after successful registration
        navigate("/kyc-process");
      } catch (error) {
        console.error("Error registering user:", error);
        // Handle error (e.g., show error toast or message)
      }
    }
  };

  useEffect(() => {
    // Retrieve phone number from localStorage
    const storedPhoneNumber = localStorage.getItem("phone-number");

    if (!storedPhoneNumber) {
      // If phone number is not found, redirect to the login page
      navigate("/login");
    } else {
      setPhoneNumber(storedPhoneNumber);

      // Fetch user details using the phone number from localStorage
      const fetchUserDetails = async () => {
        try {
          const response = await axios.post(
            "https://speedrent.in/v2Apis/production/login/getUserInfo.php",
            new URLSearchParams({
              api_key: "Pavan@2024",
              number: storedPhoneNumber,
            })
          );

          const userData = response.data.data;

          if (userData && userData.name) {
            // Redirect to '/kyc-step-one' if name is already present
            navigate("/kyc-process");
          } else {
            // Set the state with user data
            setName(userData?.name || "");
            setEmail(userData?.email || "");
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
          // Handle error (e.g., show error toast or message)
        }
      };

      fetchUserDetails();
    }

    const showSuccessToast = localStorage.getItem("show-success-toast");

    if (showSuccessToast === "true") {
      setToastMessage("Successfully logged in!");
      setShowToast(true);
      localStorage.removeItem("show-success-toast"); // Remove the flag after showing the toast
    }
  }, [navigate]); // Include 'navigate' in the dependency array

  useEffect(() => {
    // Validate the form - check if the name is filled and phone number is available
    if (name.trim() !== "" && phoneNumber.trim() !== "") {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [name, phoneNumber]); // Watch for changes in name and phone number

  return (
    <div className="row vh-100 overflow-x-hidden login-page-main-container ms-lg-2 ms-1">
      <div className="d-none d-lg-block">
        <Navbar className="position-absolute w-100">
          <Container>
            <Navbar.Brand href="/home">
              <img
                src={fullsizelogo}
                width="90"
                height="95"
                className="d-inline-block align-top mt-3"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end mb-5">
              <div className="position-relative d-flex justify-content-end">
                <button
                  className="px-5 skip-btn-login-page text-dark rounded py-1"
                  onClick={() => navigate("/home")}
                >
                  SKIP
                </button>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="col-lg-6 col-xl-6 col-md-12 d-sm-flex d-none justify-content-center">
        <div className="image-container my-lg-auto align-items-end d-lg-flex d-none">
          <img
            className={`img-fluid p-lg-5 px-5 ${
              isImageLoaded ? "image-slide-in" : ""
            }`}
            src={loginleftimg}
            alt="alternative"
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
      </div>
      <div className="d-flex d-lg-none">
        <Navbar className="fixed-top navmobileregister mx-0">
          <Container>
            <Navbar.Brand href="/home">
              <img
                src={fullsizelogo}
                width="50"
                height="57"
                className="d-inline-block align-top ps-1"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <div className="position-relative d-flex justify-content-end">
                <button
                  className="skip-btn-login-pageinside me-3 py-1 px-3"
                  onClick={() => navigate("/home")}
                >
                  SKIP
                </button>
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="col-lg-6 col-xl-6 mx-auto text-start px-lg-5 mt-lg-0 mt-md-5 mt-5 py-lg-0 py-5">
        <div className="bg-light rounded-4 shadow p-lg-5 px-3 py-4 mx-md-5 mx-sm-4 mx-lg-3 px-md-5 my-lg-0">
          <div className="pb-1">
            <h4>Register your account</h4>
            <p style={{ fontSize: "14px" }}>
              Create an account to easily rent a vehicle of your choice.
            </p>
          </div>
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formProfilePicture" className="mb-3">
              <Form.Label>
                <p style={{ fontSize: "14px" }} className="m-0 p-0">
                  Profile Picture
                </p>
              </Form.Label>

              <div className="upload-box d-flex align-items-center justify-content-center position-relative">
                {profilePicturePreview ? (
                  <>
                    <img
                      src={profilePicturePreview}
                      alt="Profile Preview"
                      className="uploaded-image"
                      style={{
                        width: "85px",
                        height: "80px",
                        objectFit: "cover",
                        borderRadius: "8px",
                      }}
                    />
                    <button
                      type="button"
                      className="delete-icon bg-light shadow rounded-2 border m-0 px-2 py-1"
                      onClick={handleDeleteProfilePicture}
                    >
                      <FaTrashAlt color="red" />
                    </button>
                  </>
                ) : (
                  <>
                    <label htmlFor="profilePictureInput" className="plus-icon">
                      <FaPlus color="#ccc" size="24px" />
                    </label>
                    <Form.Control
                      type="file"
                      id="profilePictureInput"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      className="d-none"
                    />
                  </>
                )}
              </div>
            </Form.Group> */}

            <Form.Group controlId="formName" className="mb-3">
              <Form.Label>
                <p style={{ fontSize: "14px" }} className="m-0 p-0">
                  Name*
                </p>
              </Form.Label>
              <Form.Control
                type="text"
                value={name}
                placeholder="Enter your full name"
                onChange={handleNameChange}
                required
              />
            </Form.Group>

            <div className="row mb-3">
              <Form.Group
                className="col-4 pe-1"
                controlId="formBasicCountryCode"
              >
                <Form.Label>
                  <p style={{ fontSize: "14px" }} className="m-0 p-0">
                    Mobile
                  </p>
                </Form.Label>
                <Form.Control
                  type="text"
                  className="text-end no-field-disabled-profile"
                  value={countryCode}
                  disabled
                  placeholder="+91" // This is the default country code
                />
              </Form.Group>
              <Form.Group className="col-8 ps-1" controlId="formBasicPhone">
                <Form.Label>
                  <p style={{ fontSize: "14px" }} className="m-0 p-0">
                    &nbsp;
                  </p>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={phoneNumber}
                  disabled
                  required
                  className="text-start no-field-disabled-profile"
                  placeholder="Please login to register"
                />
              </Form.Group>
            </div>

            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>
                <p style={{ fontSize: "14px" }} className="m-0 p-0">
                  Email Address
                </p>
              </Form.Label>
              <Form.Control
                type="email"
                value={email}
                placeholder="Enter your email address (optional)"
                onChange={handleEmailChange}
              />
            </Form.Group>

            <button
              type="submit"
              className="w-100 py-lg-2 py-3 mt-2 register-btn rounded-3"
              disabled={!isFormValid} // Disable button if form is invalid
            >
              REGISTER
            </button>
          </Form>
        </div>
      </div>

      {/* Toast for Successful Login Notification */}
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
