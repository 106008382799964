import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import {
  FaFacebookF,
  FaYoutube,
  FaInstagram,
  FaWhatsapp,
  FaPhone,
} from "react-icons/fa";
import mainlogo from "../../assets/full-size-logo.png";
import "./HeroFooter.css";
import { useNavigate } from "react-router-dom";

const HeroFooter = () => {
  const navigate = useNavigate();
  return (
    <footer className="bg-light border-top pt-4">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={3} className="text-md-start mb-3 mb-lg-0">
            <ul className="list-unstyled">
              <li>
                <img
                  src={mainlogo}
                  width="95"
                  height="110"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
              </li>
              <li>
                <button
                  className="footer-column-three-btn-book m-1 shadow border rounded text-light px-3 py-2"
                  onClick={() => navigate("/bike-booking")}
                >
                  Book Now
                </button>
              </li>
              <li className="p-1">
                <i class="fa-solid fa-envelope fa-icon-footer"></i>
                <a
                  href="mailto:speedrentbikes@gmail.com"
                  className="footer-quick-link-address"
                >
                  speedrentbikes@gmail.com
                </a>
              </li>
              <li className="p-1">
                <i class="fa-solid fa-location-dot fa-icon-footer"></i>
                <a
                  href="https://maps.app.goo.gl/6rgPF5kxJ166jvfQ7"
                  className="footer-quick-link-address"
                >
                  Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar,
                  Odisha 751024.
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4} lg={3} className="text-md-start mb-3 mb-lg-0">
            <ul className="list-unstyled">
              <li>
                <h4 className="footer-column-header">
                  <u>Quick Links</u>
                </h4>
              </li>
              <li className="p-1">
                <a href="/bike-booking" className="footer-quick-link">
                  <i class="fa-solid fa-reply fa-arrow-footer me-2"></i>
                  Bike Rental in Bhubaneswar
                </a>
              </li>
              <li className="p-1">
                <a href="/bike-booking" className="footer-quick-link">
                  <i class="fa-solid fa-reply fa-arrow-footer me-2"></i>
                  Bike Rental in Cuttack
                </a>
              </li>
              <li className="p-1">
                <a href="/bike-booking" className="footer-quick-link">
                  <i class="fa-solid fa-reply fa-arrow-footer me-2"></i>
                  Bike Rental in Bangalore
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4} lg={3} className="text-md-start mb-3 mb-lg-0">
            <ul className="list-unstyled">
              <li>
                <h4 className="footer-column-header">
                  <u>Terms & Policies</u>
                </h4>
              </li>
              <li className="p-1">
                <a href="/company-policy" className="footer-quick-link">
                  <i class="fa-solid fa-file-lines fa-icon-footer"></i>
                  Privacy Policy
                </a>
              </li>
              <li className="p-1">
                <a href="/terms-and-condition" className="footer-quick-link">
                  <i class="fa-solid fa-file-lines fa-icon-footer"></i>
                  Terms & Conditions
                </a>
              </li>
              <li className="p-1">
                <a
                  href="/company-refund-cancelletion"
                  className="footer-quick-link"
                >
                  <i class="fa-solid fa-file-lines fa-icon-footer"></i>
                  Refund & Cancellations
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={12} sm={4} lg={3} className="text-md-start mb-3 mb-lg-0">
            <ul className="list-unstyled">
              <li>
                <h4 className="footer-column-header">
                  <u>Get it from</u>
                </h4>
              </li>
              {/* <li>
                <a href="tel:9124046510">
                  <Button className="footer-column-three-btn m-1">
                    <i class="fa-solid fa-phone me-2"></i>
                    Phone
                  </Button>
                </a>
                <a href="https://api.whatsapp.com/send/?phone=9124046510&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                  <Button className="footer-column-three-btn m-1">
                    <i class="fa-brands fa-whatsapp me-2"></i>
                    Whatsapp
                  </Button>
                </a>
              </li> */}
              <li>
                <a href="https://play.google.com/store/apps/details?id=com.customer.speedrent" target="_blank" rel="noopener noreferrer">
                  <Button className="footer-column-three-btn m-1">
                    <i class="fa-brands fa-lg fa-apple me-2"></i>
                    App Store
                  </Button>
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.customer.speedrent" target="_blank" rel="noopener noreferrer">
                  <Button className="footer-column-three-btn m-1">
                    <i class="fa-brands fa-google-play me-2"></i>
                    Play Store
                  </Button>
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        {/* Second Row */}
        <Row className="mt-3 pt-2 border-top">
          <Col md={6} className="text-right p-1">
            <a id="footer-icons" href="tel:9124046510">
              <FaPhone className="mx-2" />
            </a>
            <a id="footer-icons" href="https://api.whatsapp.com/send/?phone=9124046510&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp className="mx-2" />
            </a>
            <a id="footer-icons" href="https://www.facebook.com/profile.php?id=61555955444703&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
              <FaFacebookF className="mx-2" />
            </a>
            <a id="footer-icons" href="https://www.instagram.com/speedrent_bike_rental/?igsh=MjZ3NmszZmV5eWho" target="_blank" rel="noopener noreferrer">
              <FaInstagram className="mx-2" />
            </a>
            <a id="footer-icons" href="https://www.youtube.com/@Speedrent-mg3zo" target="_blank" rel="noopener noreferrer">
              <FaYoutube className="mx-2" />
            </a>
          </Col>
          <Col md={6} className="text-left p-1">
            <p>© 2023 Speed Rent. All Rights Reserved</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default HeroFooter;