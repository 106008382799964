import React from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useState, useEffect } from "react";

export default function BookingDetailsModal({ show, onHide, booking }) {
  const [showLoccs, setShowLoccs] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const parseDateTime = (dateStr, timeStr) => {
    // Split the date string into day, month, and year
    const [day, month, year] = dateStr.split("-").map(Number);

    // Split time and period (AM/PM)
    let [time, period] = timeStr.split(" ");
    let [hours, minutes] = time.split(":").map(Number);

    // Convert 12-hour time format to 24-hour format
    if (period === "PM" && hours < 12) {
      hours += 12;
    } else if (period === "AM" && hours === 12) {
      hours = 0; // Midnight case
    }

    // Create and return the Date object
    return new Date(year, month - 1, day, hours, minutes);
  };

  const calculateTimeFareDuration = () => {
    if (
      !booking.pickupDate ||
      !booking.pickupTime ||
      !booking.dropDate ||
      !booking.dropTime
    ) {
      return 0;
    }

    const pickupDateTime = parseDateTime(
      booking.pickupDate,
      booking.pickupTime
    );
    const dropDateTime = parseDateTime(booking.dropDate, booking.dropTime);

    const timeDifferenceMs = dropDateTime - pickupDateTime;

    // Convert milliseconds to hours, keeping the decimal places
    const hours = timeDifferenceMs / (1000 * 60 * 60);

    // Return the result with one decimal point (optional)
    return hours.toFixed(1);
  };

  const handleCloseLoccs = () => setShowLoccs(false);

  const handleShowLoccs = () => {
    setShowLoccs(true);
  };

  const handleClick = () => {
    const phoneNumber = "9124046510";
    window.location.href = `tel:${phoneNumber}`;
  };

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phone-number");
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }
  }, []);

  const renderLocation = () => {
    if (!booking || !booking.branch) {
      return <p>Location details not available</p>;
    }

    if (booking.branch === "PATIA") {
      return (
        <span>
          <div className="modal-spec-inside mb-2">
            <i className="fa-solid fa-location-dot me-1 bike-modal-icon"></i>
            Plot No 280 Starcity Road Near RTO-II, Patia, Bhubaneswar, Odisha
            751024.
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59868.451910514756!2d85.7823011298236!3d20.3094440282536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909479cd880d9%3A0xfa93d10a51f53de0!2sRegional%20Transport%20Office%20Bhubaneswar%20II!5e0!3m2!1sen!2sin!4v1724859653743!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style="border:0.5px solid #000000;border-radius: 10px;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>`,
            }}
          />
        </span>
      );
    } else if (booking.branch === "JAGAMARA") {
      return (
        <span>
          <div className="modal-spec-inside mb-2">
            <i className="fa-solid fa-location-dot me-1 bike-modal-icon"></i>
            613/2194, Barabari Rd, Phase-I, Jagamara, Bhubaneswar, Odisha
            751030.
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d912.713407641781!2d85.79470438258764!3d20.262555023985048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjDCsDE1JzQ0LjYiTiA4NcKwNDcnNDQuMiJF!5e0!3m2!1sen!2sin!4v1724860076218!5m2!1sen!2sin"
                width="100%"
                height="100%"
                style="border:0.5px solid #000000;border-radius: 10px;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade">
              </iframe>`,
            }}
          />
        </span>
      );
    } else {
      return null;
    }
  };

  const renderStatus = () => {
    if (!booking || booking.status === undefined) {
      return <span>NA</span>;
    }

    switch (booking.status) {
      case "1":
        return (
          <div
            className="mb-3 px-3 text-center py-1"
            style={{
              color: "#ff6600",
              backgroundColor: "#ffefe5",
              borderBottom: "0.33px solid #ff6600",
              fontWeight: "500",
            }}
          >
            Ongoing!
          </div>
        );
      case "2":
        return (
          <div
            className="mb-3 px-3 text-center py-1"
            style={{
              color: "#00b950",
              backgroundColor: "#e5f8ed",
              borderBottom: "0.33px solid #00b950",
              fontWeight: "500",
            }}
          >
            Booking Confirmed!
          </div>
        );
      case "3":
        return (
          <div
            className="mb-3 px-3 text-center py-1"
            style={{
              color: "#00b950",
              backgroundColor: "#e5f8ed",
              borderBottom: "0.33px solid #00b950",
              fontWeight: "500",
            }}
          >
            Completed!
          </div>
        );
      case "4":
        return (
          <div
            className="mb-3 px-3 text-center py-1"
            style={{
              color: "#fea53f",
              backgroundColor: "#fff6ec",
              borderBottom: "0.33px solid #fea53f",
              fontWeight: "500",
            }}
          >
            Booked, Pending Payment!
          </div>
        );
      default:
        return <span>NA</span>;
    }
  };

  // const redirectToPaymentPage = () => {
  //   const phoneNumber = localStorage.getItem("phone-number");
  //   const bookingId = booking.bookingId;
  //   const amount = booking.bookingAmount;
  //   const paymentUrl = `https://speedrent.in/adminapp/cashfree/createOrder.php?phone=${phoneNumber}&amount=${amount}&bookingId=${bookingId}`;

  //   window.location.href = paymentUrl;
  // };

  return (
    <>
      <Modal
        size="lg"
        centered
        show={show}
        onHide={onHide}
        fullscreen="lg-down"
        style={{
          backdropFilter: "blur(3.33px)",
          backgroundColor: "rgba(255, 255, 255, 0.33)",
        }}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <span
              style={{
                fontSize: "medium",
                fontWeight: "500",
              }}
            >
              <i>
                Booking ID:&nbsp;
                <span>{booking?.bookingId || "error"}</span>
              </i>
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0 px-0">
          {booking ? (
            <>
              {renderStatus()}
              <div className="px-3">
                <div className="row px-md-3 px-2">
                  <div className="col-lg-6">
                    <span className="row d-flex align-items-center">
                      <img
                        src={`https://speedrent.in/adminapp/uploads/${booking.bikeNumber}.png`}
                        alt="Bike"
                        style={{
                          width: 77,
                          height: 77,
                          objectFit: "cover",
                          padding: "2px",
                          border: "0.33px solid #b7b7b7",
                          backgroundColor: "#f3f3f3",
                        }}
                        className="col-4 rounded-3"
                      />

                      <div className="d-flex flex-column justify-content-between col-5 text-start p-0 ps-2 my-auto">
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "medium",
                          }}
                        ></div>
                        <div
                          className="conf-bike-model"
                          style={{
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <strong>{booking.modelName}</strong>
                        </div>
                        <span
                          style={{
                            color: "#ff6600",
                            fontSize: "smaller",
                            fontWeight: "500",
                          }}
                        >
                          <i class="fa-solid fa-route sa-sm me-1"></i>
                          {booking?.kmLimit || "120"}Km Free
                        </span>
                      </div>
                      <div className="ms-auto col-3 text-end">
                        <span className="text-success fw-bold">
                          ₹&nbsp;{booking.bookingAmount}
                        </span>
                      </div>
                    </span>
                    <hr />
                    <span className="row d-flex align-items-center conf-mod-datettime mt-lg-3 mt-1 rounded-3 border py-2 px-2">
                      <div className="d-flex flex-column justify-content-between col-5 p-0">
                        <div className="text-start startenddatetime-confmod">
                          <span>
                            {booking.pickupDate}, {booking.pickupTime}
                          </span>
                        </div>
                      </div>
                      <div className="col-2 text-center arrw-confmod p-0">
                        <span>&#8594;</span>
                      </div>
                      <div className="col-5 text-end startenddatetime-confmod p-0">
                        <span>
                          <span>
                            {booking.dropDate}, {booking.dropTime}
                          </span>
                        </span>
                      </div>
                    </span>
                    <span className="row d-flex align-items-center mt-lg-2 mt-1">
                      <div className="d-flex flex-column justify-content-between col-6 text-start">
                        <div>
                          <span className="pik-loc-confmod">
                            Pickup Location
                          </span>
                        </div>
                        <div className="conf-book-branch">
                          <i className="fa-solid fa-location-dot fa-sm me-1 conf-bike-model-location"></i>
                          <span>{booking.branch}</span>
                        </div>
                      </div>
                      <div className="text-end ms-auto col-6">
                        <span className="text-dark fw-bold">
                          <button
                            className="rounded direct-btn-confirm-modal border px-2 py-1"
                            onClick={(e) => {
                              e.stopPropagation(); // This prevents any event bubbling that might interfere
                              handleShowLoccs(); // Ensure the function is correctly called
                            }}
                          >
                            <i className="fa-solid fa-diamond-turn-right"></i>
                          </button>
                        </span>
                      </div>
                    </span>
                    <span className="row mt-lg-2 mt-2">
                      <button
                        className="rounded py-md-2 py-2 border contact-btn-confirm-modal"
                        onClick={handleClick}
                      >
                        Contact Vendor
                      </button>
                    </span>
                  </div>
                  <div
                    className="col-lg-6 row p-0 m-0 mt-lg-0 mt-3"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <hr className="d-lg-none" />
                    <div>
                      <span className="text-start conf-mod-bildetheader">
                        Billing Details
                      </span>
                      <br />
                      <span>
                        <span className="d-flex justify-content-between align-items-center mt-2">
                          <span className="confmod-bildet-lefttext">
                            Time Fare ({calculateTimeFareDuration()} hrs)
                          </span>
                          <span className="confmod-bildet-righttext text-success">
                            ₹&nbsp;{booking.bookingAmount}
                          </span>
                        </span>
                        <span className="d-flex justify-content-between align-items-center mt-1">
                          <span className="confmod-bildet-lefttext">
                            Free Km
                          </span>
                          <span className="confmod-bildet-righttext">
                            {booking?.kmLimit || "120"}&nbsp;Km
                          </span>
                        </span>
                        <span className="d-flex justify-content-between align-items-center mt-1">
                          <span className="confmod-bildet-lefttext">
                            Convenience Fee
                          </span>
                          <span className="confmod-bildet-righttext">
                            ₹&nbsp;50.00
                          </span>
                        </span>
                        <span className="d-flex justify-content-between align-items-center mt-1 confmod-bildet-lefttext-discount">
                          <span>Discount</span>
                          <span className="confmoddiskont">-₹&nbsp;50.00</span>
                        </span>
                        <hr />
                        <span className="d-flex justify-content-between align-items-center confmod-bildet-totamt px-2">
                          <span>Total Amount</span>
                          <span>₹&nbsp;{booking.bookingAmount}</span>
                        </span>
                        <hr />
                      </span>
                    </div>
                    <div>
                      <span className="text-start conf-mod-bildetheader">
                        Personal Details
                      </span>
                      <Form className="mt-2">
                        <Form.Group className="mb-2" controlId="formBasicEmail">
                          <Form.Label>
                            <span className="form-confmod-text">
                              Phone Number
                            </span>
                          </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Please Login"
                            disabled
                            className="confmod-primob"
                            value={"+91 " + phoneNumber}
                          />
                        </Form.Group>
                        {booking.secondaryNumber && (
                          <Form.Group
                            className="mb-2"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>
                              <span className="form-confmod-text">
                                Alternate Number
                              </span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Secondary Number"
                              disabled
                              className="confmod-primob"
                              value={"+91 " + booking.secondaryNumber}
                            />
                          </Form.Group>
                        )}
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <p>No booking details available.</p>
          )}
        </Modal.Body>
        {/* {booking && booking.status == "4" && (
          <Modal.Footer className="px-3 py-2">
            <Button
              variant="success"
              className="w-100"
              onClick={redirectToPaymentPage}
            >
              <i class="fa-solid fa-dollar-sign me-1 fa-sm"></i>
              Pay Now
            </Button>
          </Modal.Footer>
        )} */}
      </Modal>
      <Modal
        show={showLoccs}
        onHide={handleCloseLoccs}
        centered
        style={{
          backdropFilter: "blur(3.33px)",
          backgroundColor: "rgba(255, 255, 255, 0.33)",
        }}
      >
        <Modal.Header
          closeButton
          style={{
            borderBottom: "3.33px solid #ff6600",
          }}
        >
          <Modal.Title>Pickup Location!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="pb-3">
            <div className="modal-spec pb-1">Pickup Location</div>
            {renderLocation()}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
