import React from "react";
import { Button } from "react-bootstrap";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import HeroFooter from "../hero-footer/HeroFooter";
import { useNavigate } from "react-router-dom";

const TermsCondition = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeroNavBar showCenterNav={false} />
      <div className="text-start container my-5 col-lg-9 col-md-10 col-11">
        <div className="my-3 d-flex">
          <Button
            className="rounded-5"
            style={{ backgroundColor: "#000000", border: "none" }}
            onClick={() => navigate("/home")}
          >
            <i class="fa-solid fa-lg fa-arrow-left"></i>
          </Button>
        </div>
        <div>
          <h3 className="text-center">
            <u>Terms & Conditions</u>
          </h3>
          <br />
          1.You need submit original copy of govt.id proof and show driving
          licence.
          <br />
          <br />
          2. There is km limit 130 km per day ( 24 hrs) scooty / bike ( below
          115 cc ) i.e ( 5 km / 1 hr) and 150 km per day ( 24 hrs) ( 7 km / 1
          hr) for bike (above 110 cc)
          <br />
          <br />
          after that you will be charged Rs. 5/- per km in 110cc and Rs .7/- per
          km in above 110 cc. ex - if a customer booked 6 hr then km limit is 30
          <br />
          <br />
          3.The Security deposite Amount will be refundable after the
          insperation of vehicle & it will take 5 to 7 days working hour.
          <br />
          <br />
          4. If you wish to execeed tour trip you need to call our customer care
          service. Otherwise take penality charge 150/- per hour.
          <br />
          <br />
          5. In case of damage to motorcycle due accident or otherwise you are
          libel to pay the full amount to make bike serviceable and you have to
          pay the per day rental till the time bike will be available for
          renting again.
          <br />
          <br />
          6. In case of theft full value of motorcycle .
          <br />
          <br />
          7. in case of damage or loss helmet you are to pay Rs.900/-
          <br />
          <br />
          8. All the traffic Challan penalties for violation of traffic rules is
          the sole responsibility of the rider and must paid of traffic
          authority.
          <br />
          <br />
          9.We are provided fuel the to nearest petrol pump. If a customer
          provided more fuel at the drop of time we are not responsibility
          otherwise take fuel own risk.
          <br />
          <br />
          10. Booking Amount not Refundable.
          <br />
          <br />
          11. For monthly booking total km limit is 1200 excess 4 rupees per km.
          <br />
          <br />
          12. you must properly check out the veicle before leaving in our
          rental location.tire and puncture is not responsible in our company.
          <br />
          <br />
          13. Amount of cancellation charges as follows:
          <br />
          <br />
          (a) 0-24 hrs of the pickup time - 100% deduction
          <br />
          <br />
          (b) 24-72 hrs of the pick up time - 50% deduction
          <br />
          <br />
          (C) before 72 hrs of the pick up time full refund
          <br />
          <br />
          <h4>Prohibited Uses :</h4>
          The uses of a lessors vehicle under the following condition is
          prohibited. Our Motorcycle Can not use for rallies and rally so
          charges for any format or professional or amateur competitions and
          media uses.
          <br />
          <br />
          By any person who under the influence of (i) alcohol or (II) any drug
          or medication under the effect of which operation a vehicle prohibited
          or not recommended In carrying out of any crime or any other we take
          legal activities.
          <br />
          <br />
          <h4>With regards</h4>
          Speedrent (A unit of subrat bike rentals)
          <br />
        </div>
      </div>
      <HeroFooter />
    </>
  );
};

export default TermsCondition;
