import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./HeroNavBar.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

function MobileFilter({
  show,
  handleClose,
  onSortChange,
  onTransmissionChange,
  onFuelChange,
}) {
  const [selectedSort, setSelectedSort] = useState(null);
  const [selectedTransmission, setSelectedTransmission] = useState(null);
  const [selectedFuel, setSelectedFuel] = useState(null);

  const handleSortSelect = (value) => {
    setSelectedSort(value);
    onSortChange(value); // Pass to parent
  };

  const handleTransmissionSelect = (value) => {
    setSelectedTransmission(value);
    onTransmissionChange(value); // Pass to parent
  };

  const handleFuelSelect = (value) => {
    setSelectedFuel(value);
    onFuelChange(value); // Pass to parent
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="fs-6">Filter your ride!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <div className="row">
            <div className="col-3">
              <Nav variant="pills" className="flex-column">
                <Nav.Item>
                  <Nav.Link eventKey="first">Sort By</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="second">Transmission Type</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="third">Fuel Type</Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <div className="col-9 text-end">
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <div className="pe-3 txt-fltr">
                    Sort By
                    <i class="fa-solid fa-sort ms-1"></i>
                  </div>
                  <div className="p-2 ps-3">
                    <button
                      className={`${
                        selectedSort === "1"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleSortSelect("1")}
                    >
                      Price: Low to High
                    </button>
                    <button
                      className={`${
                        selectedSort === "2"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleSortSelect("2")}
                    >
                      Price: High to Low
                    </button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <div className="pe-3 txt-fltr">
                    Transmission Type
                    <i class="fa-solid fa-gears ms-1"></i>
                  </div>
                  <div>
                    <button
                      className={`${
                        selectedTransmission === "AUTOMATIC"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleTransmissionSelect("AUTOMATIC")}
                    >
                      Mopped
                    </button>
                    <button
                      className={`${
                        selectedTransmission === "GEAR"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleTransmissionSelect("GEAR")}
                    >
                      Gear
                    </button>
                    <button
                      className={`${
                        selectedTransmission === "All"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleTransmissionSelect("All")}
                    >
                      All
                    </button>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <div className="pe-3 txt-fltr">
                    Fuel Type
                    <i class="fa-solid fa-gas-pump ms-1"></i>
                  </div>
                  <div className="p-2">
                    <button
                      className={`${
                        selectedFuel === "PETROL"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleFuelSelect("PETROL")}
                    >
                      Petrol
                    </button>
                    <button
                      className={`${
                        selectedFuel === "ELECTRIC"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleFuelSelect("ELECTRIC")}
                    >
                      Electric
                    </button>
                    <button
                      className={`${
                        selectedFuel === "All"
                          ? "active filter-btn-mobile"
                          : "filter-btn-mobile-before"
                      }`}
                      onClick={() => handleFuelSelect("All")}
                    >
                      All
                    </button>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </div>
        </Tab.Container>
        <div className="d-flex justify-content-end">
          <button
            className="text-dark px-3 py-2 rounded mobile-filter-close-btn"
            onClick={handleClose}
          >
            Clear
          </button>
          <button
            className="mobile-filter-apply-btn px-3 py-2 rounded"
            onClick={handleClose}
          >
            Apply
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default MobileFilter;
