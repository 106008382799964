import React, { useState, useEffect } from "react";
import { Navbar, Button, Modal } from "react-bootstrap";
import StartDateModal from "./StartDateModal";
import EndDateModal from "./EndDateModal";
import "react-datepicker/dist/react-datepicker.css";
import "./FilterBooking.css";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function FilterBooking({
  onStartDateTimeChange,
  onEndDateTimeChange,
  onSortByPriceChange,
  onFilterByTransmissionChange,
  onFilterByFuelTypeChange,
}) {
  const [showStartModal, setShowStartModal] = useState(false);
  const [showEndModal, setShowEndModal] = useState(false);
  const [selectedStartDateTime, setSelectedStartDateTime] = useState(null);
  const [selectedEndDateTime, setSelectedEndDateTime] = useState(null);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [duration, setDuration] = useState(null);
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);

  useEffect(() => {
    let lastScrollY = window.scrollY;
    const handleScroll = () => {
      if (window.scrollY > lastScrollY && window.scrollY > 100) {
        setIsNavbarVisible(false);
      } else if (window.scrollY < lastScrollY) {
        setIsNavbarVisible(true);
      }
      lastScrollY = window.scrollY;
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowStartModal = () => setShowStartModal(true);
  const handleCloseModal = () => {
    setShowStartModal(false);
    setShowEndModal(false);
  };

  const handleShowEndModal = () => {
    if (!selectedStartDateTime) {
      // Show toast notification if start date/time is not selected
      toast.error("Choose Start Date & Time first", {
        position: "top-center", // Use string for position
        autoClose: 3000, // Close after 3 seconds
      });
    } else {
      setShowEndModal(true);
    }
  };

  const handleApplyStart = (dateTime) => {
    setSelectedStartDateTime(dateTime);
    onStartDateTimeChange(dateTime);
    handleCloseModal();
  };

  const handleApplyEnd = (dateTime) => {
    if (selectedStartDateTime) {
      const startTime = new Date(
        selectedStartDateTime.date.getFullYear(),
        selectedStartDateTime.date.getMonth(),
        selectedStartDateTime.date.getDate(),
        parseInt(selectedStartDateTime.time.split(":")[0]) +
          (selectedStartDateTime.ampm === "PM" ? 12 : 0),
        parseInt(selectedStartDateTime.time.split(":")[1])
      );

      const endTime = new Date(
        dateTime.date.getFullYear(),
        dateTime.date.getMonth(),
        dateTime.date.getDate(),
        parseInt(dateTime.time.split(":")[0]) +
          (dateTime.ampm === "PM" ? 12 : 0),
        parseInt(dateTime.time.split(":")[1])
      );

      const timeDifferenceMs = endTime - startTime; // difference in milliseconds

      if (timeDifferenceMs < 6 * 60 * 60 * 1000) {
        setSelectedEndDateTime(null); // Clear end date and time
        handleShowWarningModal(); // Show warning modal
        onEndDateTimeChange(null, null);
      } else {
        setSelectedEndDateTime(dateTime);
        const hours = Math.floor(timeDifferenceMs / (1000 * 60 * 60));
        const minutes = Math.floor(
          (timeDifferenceMs % (1000 * 60 * 60)) / (1000 * 60)
        );
        const calculatedDuration = `${hours}:${minutes
          .toString()
          .padStart(2, "0")}`;
        setDuration(calculatedDuration);
        onEndDateTimeChange(dateTime, calculatedDuration);
      }
    } else {
      setSelectedEndDateTime(dateTime);
      onEndDateTimeChange(dateTime);
    }
    handleCloseModal();
  };

  const formatDateTime = ({ date, time, ampm }) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const formattedTime = `${time} ${ampm}`;
    return `${day} ${month}, ${formattedTime}`;
  };

  const handleShowWarningModal = () => {
    setShowWarningModal(true);
    setTimeout(() => {
      setShowWarningModal(false);
    }, 3500); // Close after 3.5 seconds
  };

  const handleCloseWarningModal = () => setShowWarningModal(false);

  return (
    <>
      <Navbar
        className={`bg-body-tertiary d-flex justify-content-between px-lg-4 border-bottom ${
          isNavbarVisible ? "navbar-visible" : "navbar-hidden"
        }`}
      >
        <div
          className="border rounded d-flex align-items-center mx-auto mx-lg-0"
          style={{ backgroundColor: "#ffffff" }}
        >
          {/* Start Date & Time Button */}
          <Button
            style={{ backgroundColor: "#ffffff" }}
            variant="light"
            onClick={handleShowStartModal}
            className={
              selectedStartDateTime
                ? "text-muted small-text small-device-arbtnhol"
                : "btnbftthe"
            }
          >
            Start Date & Time
            <i className="fa-solid fa-caret-down ms-1"></i>
            {selectedStartDateTime && (
              <div className="fw-bold small-device-arbtn">
                {formatDateTime(selectedStartDateTime)}
              </div>
            )}
          </Button>

          {/* Duration Arrow and End Date & Time */}
          <div className="d-flex flex-column align-items-center mx-2">
            {duration && (
              <div
                className="fw-semibold arwtextsmall"
                style={{ marginBottom: "-10px", fontSize: "10px" }}
              >
                {duration} hr
              </div>
            )}
            <span className="px-lg-2 px-md-1">→</span>
          </div>

          {/* End Date & Time Button */}
          <Button
            style={{ backgroundColor: "#ffffff" }}
            variant="light"
            onClick={handleShowEndModal}
            className={
              selectedEndDateTime
                ? "text-muted small-text small-device-arbtnhol"
                : "btnbftthe"
            }
          >
            End Date & Time
            <i className="fa-solid fa-caret-down ms-1"></i>
            {selectedEndDateTime && (
              <div className="fw-bold small-device-arbtn">
                {formatDateTime(selectedEndDateTime)}
              </div>
            )}
          </Button>
        </div>

        {/* Filters */}
        <div className="d-none d-lg-flex">
          <div className="mx-1">
            <Form.Select
              aria-label="Sort by Price"
              onChange={(e) => onSortByPriceChange(e.target.value)}
            >
              <option disabled selected>
                Sort by:
              </option>
              <option value="1">Price: Low to High</option>
              <option value="2">Price: High to Low</option>
            </Form.Select>
          </div>
          <div className="mx-1">
            <Form.Select
              aria-label="Transmission Type"
              onChange={(e) => onFilterByTransmissionChange(e.target.value)}
            >
              <option disabled selected>
                Transmission Type:
              </option>
              <option value="AUTOMATIC">MOPPED</option>
              <option value="GEAR">GEAR</option>
              <option value="All">All</option>
            </Form.Select>
          </div>
          <div className="ms-1">
            <Form.Select
              aria-label="Fuel Type"
              onChange={(e) => onFilterByFuelTypeChange(e.target.value)}
            >
              <option disabled selected>
                Fuel Type:
              </option>
              <option value="PETROL">Petrol</option>
              <option value="ELECTRIC">Electric</option>
              <option value="All">All</option>
            </Form.Select>
          </div>
        </div>

        {/* Modals */}
        <StartDateModal
          showStartModal={showStartModal}
          handleCloseModal={handleCloseModal}
          handleApply={handleApplyStart}
        />

        <EndDateModal
          showEndModal={showEndModal}
          handleCloseModal={handleCloseModal}
          handleApply={handleApplyEnd}
        />

        {/* Warning Modal */}
        <Modal
          centered
          show={showWarningModal}
          onHide={handleCloseWarningModal}
        >
          <Modal.Body>
            <div className="row">
              <div className="text-center col-12">
                Please ensure bookings are made for a{" "}
                <span>minimum duration of 6 hours</span>.
                <br />
                <span className="text-danger">
                  Please Select Valid Date And Time!
                </span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </Navbar>
      <ToastContainer />
    </>
  );
}

export default FilterBooking;
