import React from "react";
import mainlogo from "../../assets/full-size-logo.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div>
      <div className="p-5 m-5">
        <h5>
          <i>
            Page Not Found <br />
            <a href="/bike-booking">
              <span style={{ color: "#ff6600", fontSize: "xx-large" }}>
                Book a ride!
              </span>
            </a>{" "}
            now
          </i>
        </h5>
      </div>
    </div>
  );
}
