import React from "react";
import emptyListing from "../../assets/booking-notice-icon.png";

export default function EmptyListing() {
  return (
    <>
      <div className="vh-100 d-flex pb-5">
        <div className="m-auto pb-5">
          <div class="image-container">
            <img class="img-fluid" src={emptyListing} alt="alternative" />
            <p className="px-5 py-1 fw-bold">
              Set location & choose the Start/End date & time.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
