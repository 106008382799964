import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "../components/common/home/Home";
import LoginPage from "../components/common/login/LoginPage";
import BookingPage from "../components/common/booking/BookingPage";
import PageNotFound from "../components/common/page-not-found/PageNotFound";
import TermsCondition from "../components/common/terms-condition/TermsCondition";
import CompanyPolicy from "../components/common/policy/CompanyPolicy";
import RefundCancellation from "../components/common/refund-cancellation/RefundCancellation";
import RegisterAccount from "../components/common/register-account/RegisterAccount";
import EditProfile from "../components/common/profile-edit/EditProfile";
import KycProcess from "../components/common/kyc-uploading/KycProcess";
import DevInfo from "../components/common/booking/DevInfo";
import SuccessPage from "../components/common/success-page/SuccessPage";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<PageNotFound />} /> {/* 404Page */}
        <Route path="/" element={<Home />} /> {/* HomePage */}
        <Route path="/home" element={<Home />} />
        <Route path="" element={<Home />} /> {/* RandomLinks */}
        <Route path="/login" element={<LoginPage />} />
        {/* Change the path to accept dynamic mobile number */}
        <Route path="/register-account" element={<RegisterAccount />} />
        <Route path="/kyc-process" element={<KycProcess />} />
        <Route path="/bike-booking" element={<BookingPage />} />
        <Route path="/edit-your-speedrent-profile" element={<EditProfile />} />
        <Route path="/terms-and-condition" element={<TermsCondition />} />
        <Route path="/company-policy" element={<CompanyPolicy />} />
        <Route
          path="/webdevinfo"
          element={<DevInfo />}
        />
        <Route
          path="/company-refund-cancelletion"
          element={<RefundCancellation />}
        />
        <Route path="/payment-success" element={<SuccessPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
