import React from "react";
import { Form } from "react-bootstrap";

const CountryCodeSelector = ({ value, onChange }) => {
  return (
    <Form.Select
      aria-label="Select country code"
      value={value}
      onChange={onChange}
    >
      <option value="91">+91</option>
      {/* Add more country codes as needed */}
    </Form.Select>
  );
};

export default CountryCodeSelector;
