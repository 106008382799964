import React from "react";
import { Modal, Button } from "react-bootstrap";
import homemodal from "../../assets/home-modal.png";
import Form from "react-bootstrap/Form";

export default function LoadingPopup({ showModal, handleClose }) {
  return (
    <>
      <div>
        <Modal
          show={showModal}
          onHide={handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            className="no-border-bottom"
            style={{ border: "none" }}
          />
          <Modal.Body className="row pb-lg-4 pb-md-3 pt-md-0 pt-0 px-4">
            <div className="col-lg-6 col-xl-6 order-1 mb-md-2 mb-2 mb-lg-0">
              <div>
                <h5 className="text-start">
                  Join <span style={{ color: "#ff6600" }}>Speed Rent</span> and
                  unlock special discounts.
                </h5>
              </div>
              <div class="image-container align-items-start d-flex">
                <img class="img-fluid" src={homemodal} alt="alternative" />
              </div>
            </div>
            <div className="col-lg-6 col-xl-6 col-md-12 order-2">
              <Form>
                <div>
                  <Form.Group controlId="formName">
                    <Form.Control type="text" placeholder="Enter your name" />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId="formEmail" className="mt-3">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email address"
                    />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group controlId="formPhone" className="mt-3">
                    <Form.Control
                      type="number"
                      required
                      placeholder="Enter your mobile number"
                    />
                  </Form.Group>
                </div>
                <button
                  type="submit"
                  className="mt-4 rounded px-2 py-1 form-message-submit-btn-popup shadow-sm"
                  style={{
                    border: "1px solid #ff6600",
                    backgroundColor: "transparent",
                    color: "#ff6600",
                  }}
                >
                  Send Message
                </button>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}
