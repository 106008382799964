import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { useNavigate } from "react-router-dom";
import fullsizelogo from "../../assets/full-size-logo.png";

function LoginNav() {
  const navigate = useNavigate();
  return (
    <Navbar className="position-absolute w-100 mt-4">
      <Container>
        <Navbar.Brand href="/home">
          <img
            src={fullsizelogo}
            width="100"
            height="105"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <div className="position-relative d-flex justify-content-end">
            <button
              className="px-5 skip-btn-login-page text-dark rounded py-1"
              onClick={() => navigate("/home")}
            >
              SKIP
            </button>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default LoginNav;
