import "./Home.css";
import React, { useRef, useState, useEffect } from "react";
import HeroFooter from "../hero-footer/HeroFooter";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import LandingPage from "../landing-page/LandingPage";
import LoadingPopup from "../loading-popup/LoadingPopup";

const Home = () => {
  const landingPageRef = useRef();
  const [showModal, setShowModal] = useState(false); // State to control modal visibility

  const scrollToSection = (section) => {
    if (landingPageRef.current) {
      const { sectionThreeRef, sectionFiveRef, sectionFourRef, sectionSixRef } =
        landingPageRef.current;
      if (section === "blogs" && sectionThreeRef) {
        sectionThreeRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (section === "testimonials" && sectionFourRef) {
        sectionFourRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (section === "faqs" && sectionFiveRef) {
        sectionFiveRef.current.scrollIntoView({
          behavior: "smooth",
        });
      } else if (section === "contactUs" && sectionSixRef) {
        sectionSixRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  useEffect(() => {
    // Trigger the modal to open after 10 seconds
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => setShowModal(false);

  return (
    <div id="main-div-hero-section">
      <HeroNavBar scrollToSection={scrollToSection} />
      <LandingPage ref={landingPageRef} />
      <HeroFooter />
      <LoadingPopup showModal={showModal} handleClose={handleClose} />
    </div>
  );
};

export default Home;
