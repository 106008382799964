import React from "react";
import { Modal, Button } from "react-bootstrap";
import DateTimePicker from "./DateTimePicker";

const EndDateModal = ({ showEndModal, handleCloseModal, handleApply }) => {
  const handleApplyClick = (dateTime) => {
    handleApply(dateTime);
  };

  return (
    <Modal show={showEndModal} onHide={handleCloseModal}>
      <Modal.Body>
        <DateTimePicker
          mode="drop"
          onApply={handleApplyClick}
          handleCloseModal={handleCloseModal}
        />
      </Modal.Body>
    </Modal>
  );
};

export default EndDateModal;
