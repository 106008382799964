import React, { useState, useEffect, useRef } from "react";
import "./LoginPage.css";
import axios from "axios";
import Form from "react-bootstrap/Form";
import loginleftimg from "../../assets/login-left-img.png";
import LoginNav from "../login-nav/LoginNav";
import { Button, Spinner, Toast, ToastContainer } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import TermsContent from "../terms-condition/TermsContent";
import CountryCodeSelector from "./CountryCodeSelector.js";

export default function LoginPage() {
  const [isChecked, setIsChecked] = useState(false);
  const [isPhoneNumberEntered, setIsPhoneNumberEntered] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isOtpEntered, setIsOtpEntered] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const [validatingOtp, setValidatingOtp] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [resendOtpTimer, setResendOtpTimer] = useState(30);
  const [isResendEnabled, setIsResendEnabled] = useState(false);
  const [resendingOtp, setResendingOtp] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState("91");
  const otpInputRefs = useRef([]);

  useEffect(() => {
    if (isPhoneNumberEntered) {
      otpInputRefs.current[0].focus(); // Set focus on the first OTP input field
    }
  }, [isPhoneNumberEntered]);

  useEffect(() => {
    if (resendOtpTimer > 0 && !isResendEnabled) {
      const timer = setTimeout(() => {
        setResendOtpTimer(resendOtpTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (resendOtpTimer === 0) {
      setIsResendEnabled(true);
    }
  }, [resendOtpTimer, isResendEnabled]);

  // Check if the user is already logged in on component mount
  useEffect(() => {
    const loggedInMessage = localStorage.getItem("success-loggedin");
    const storedPhoneNumber = localStorage.getItem("phone-number");

    if (loggedInMessage === "true" && storedPhoneNumber) {
      navigate("/register-account");
    }
  }, [navigate]);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneNumberSubmit = async (event) => {
    event.preventDefault();
    setPhoneNumberError("");

    const phoneNumber = event.target.formBasicPhone.value;

    if (!validatePhoneNumber(phoneNumber)) {
      setPhoneNumberError("Please enter a valid 10-digit phone number.");
      return;
    }

    setSendingOtp(true);

    try {
      const response = await axios.post(
        "https://speedrent.in/v2Apis/production/login/getOtp.php",
        new URLSearchParams({
          api_key: "Pavan@2024",
          pnumber: phoneNumber,
        })
      );

      console.log("OTP Send Response:", response.data);

      setIsPhoneNumberEntered(true);
      setPhoneNumber(phoneNumber);
      setToastMessage("OTP sent successfully!");
    } catch (error) {
      setPhoneNumberError("Failed to send OTP. Please try again.");
      console.error("Error sending OTP:", error);
    } finally {
      setSendingOtp(false);
      setShowToast(true);
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < 5) {
      otpInputRefs.current[index + 1].focus();
    }

    const isAllOtpEntered = newOtp.every((digit) => digit !== "");
    setIsOtpEntered(isAllOtpEntered);
  };

  const handleResendOtp = async () => {
    setResendingOtp(true);
    setResendOtpTimer(30);
    setIsResendEnabled(false);

    try {
      const response = await axios.post(
        "https://speedrent.in/v2Apis/production/login/getOtp.php",
        new URLSearchParams({
          api_key: "Pavan@2024",
          pnumber: phoneNumber,
        })
      );

      console.log("Resend OTP Response:", response.data);

      setToastMessage("OTP resent successfully!");
    } catch (error) {
      setToastMessage("Failed to resend OTP. Please try again.");
      console.error("Error resending OTP:", error);
    } finally {
      setResendingOtp(false);
      setShowToast(true);
    }
  };

  const handleValidateOtp = async (event) => {
    event.preventDefault();
    setValidatingOtp(true);

    try {
      const response = await axios.post(
        "https://speedrent.in/v2Apis/production/login/verifyOtp.php",
        new URLSearchParams({
          api_key: "Pavan@2024",
          pnumber: phoneNumber,
          otp: otp.join(""),
        })
      );

      if (
        response.data.message === "success" &&
        response.data.data.otp === otp.join("")
      ) {
        // Save the "success-loggedin" message and phone number to localStorage
        localStorage.setItem("success-loggedin", "true");
        localStorage.setItem("phone-number", phoneNumber);
        localStorage.setItem("show-success-toast", "true");

        setToastMessage("Logged in successfully!");
        navigate("/register-account");
      } else {
        setToastMessage("Wrong OTP. Please try again.");
      }
    } catch (error) {
      setToastMessage("Failed to verify OTP. Please try again.");
      console.error("Error verifying OTP:", error);
    } finally {
      setValidatingOtp(false);
      setShowToast(true);
    }
  };

  const handleEditPhoneNumber = () => {
    setIsPhoneNumberEntered(false);
    setOtp(Array(6).fill(""));
    setIsOtpEntered(false);
    setIsChecked(true);
    setResendOtpTimer(30);
    setIsResendEnabled(false);
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-none d-lg-block">
        <LoginNav />
      </div>
      <div className="row vh-100 overflow-x-hidden login-page-main-container ms-lg-2 ms-1">
        <div className="col-lg-6 col-xl-6 col-md-12 d-flex justify-content-center">
          <div
            className="d-lg-none position-absolute mt-3"
            style={{ right: "10px" }}
          >
            <button
              className="px-5 skip-btn-login-page text-dark rounded py-1"
              onClick={() => navigate("/home")}
            >
              SKIP
            </button>
          </div>
          <div className="image-container my-lg-auto d-flex align-items-end">
            <img
              className={`img-fluid p-lg-5 px-5 ${
                isImageLoaded ? "image-slide-in" : ""
              }`}
              src={loginleftimg}
              alt="alternative"
              onLoad={() => setIsImageLoaded(true)}
            />
          </div>
        </div>
        <div className="col-lg-6 col-xl-6 m-lg-auto text-start px-lg-5 my-lg-auto mt-3">
          {!isPhoneNumberEntered ? (
            <div className="bg-light rounded-4 shadow p-lg-5 px-3 py-4 fixed bottom mx-md-5 mx-lg-0 px-md-5">
              <div className="pb-1">
                <h6>
                  Please enter your mobile number to get a verification code
                </h6>
              </div>
              <Form onSubmit={handlePhoneNumberSubmit}>
                <div className="row mb-3">
                  <Form.Group
                    className="col-4 pe-1"
                    controlId="formBasicCountryCode"
                  >
                    <CountryCodeSelector
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group className="col-8 ps-1" controlId="formBasicPhone">
                    <Form.Control
                      type="text"
                      required
                      placeholder="Enter your mobile number"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className={phoneNumberError ? "error-border" : ""}
                    />
                  </Form.Group>
                </div>
                {phoneNumberError && (
                  <div className="d-flex justify-content-end">
                    <span className="text-danger" style={{ fontSize: "12px" }}>
                      {phoneNumberError}
                    </span>
                  </div>
                )}
                <Form.Group
                  className="mb-3 d-flex"
                  controlId="formBasicCheckbox"
                >
                  <Form.Check
                    type="checkbox"
                    label="By clicking, I accept the Terms & Conditions."
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                  />
                  <a
                    className="rounded-5 ms-1 info-btn m-auto"
                    onClick={handleShow}
                  >
                    <i className="fa-solid m-auto info-btn fa-circle-question"></i>
                  </a>
                </Form.Group>
                <button
                  type="submit"
                  className={`w-100 get-otp-btn py-lg-2 py-3 ${
                    sendingOtp ? "sending-otp-btn" : ""
                  }`}
                  disabled={!isChecked}
                >
                  {sendingOtp ? "SENDING OTP" : "GET OTP"}
                </button>
              </Form>
            </div>
          ) : (
            <div className="bg-light shadow rounded-4 p-lg-5 px-3 py-4">
              <div className="pb-1">
                <h6>
                  Please enter a 6 digit code sent to your mobile number for
                  verification
                </h6>
              </div>
              <Form onSubmit={handleValidateOtp}>
                <div className="otp-container mb-3">
                  {otp.map((digit, index) => (
                    <Form.Group key={index}>
                      <Form.Control
                        type="text"
                        id={`otp-input-${index}`}
                        maxLength="1"
                        value={digit}
                        onChange={(e) => handleOtpChange(e.target.value, index)}
                        onKeyDown={(e) => {
                          if (
                            e.key === "Backspace" &&
                            otp[index] === "" &&
                            index > 0
                          ) {
                            otpInputRefs.current[index - 1].focus();
                          }
                        }}
                        required
                        className="otp-input"
                        style={{ textAlign: "center" }}
                        ref={(el) => (otpInputRefs.current[index] = el)}
                      />
                    </Form.Group>
                  ))}
                </div>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <a
                    className="d-flex edit-phone-otp text-dark"
                    onClick={handleEditPhoneNumber}
                  >
                    <div className="d-flex align-items-center">
                      <span>
                        <u>{phoneNumber}</u>
                      </span>
                      <div className="ms-2">
                        <i className="fa-solid fa-pen fa-sm m-1 location-icon-section-two"></i>
                        <span>EDIT</span>
                      </div>
                    </div>
                  </a>
                  <div>
                    <button
                      style={{
                        border: "none",
                        backgroundColor: "transparent",
                        color: "red",
                        fontSize: "13px",
                      }}
                      variant="link"
                      disabled={!isResendEnabled}
                      onClick={handleResendOtp}
                      className="p-0"
                    >
                      {isResendEnabled
                        ? "Resend OTP"
                        : `Resend OTP (${resendOtpTimer}s)`}
                    </button>
                  </div>
                </div>

                <Button
                  type="submit"
                  className={`w-100 get-otp-btn py-lg-2 py-3 ${
                    validatingOtp || resendingOtp ? "sending-otp-btn" : ""
                  }`}
                  disabled={!isOtpEntered || validatingOtp}
                >
                  {validatingOtp ? (
                    <>
                      VALIDATING
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="ms-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </>
                  ) : resendingOtp ? (
                    <>
                      RESENDING OTP
                      <Spinner
                        animation="border"
                        role="status"
                        size="sm"
                        className="ms-2"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </>
                  ) : isOtpEntered ? (
                    "VALIDATE"
                  ) : (
                    "ENTER OTP"
                  )}
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
      <Modal className="modal-lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TermsContent />
        </Modal.Body>
      </Modal>

      {/* Toast for Notifications */}
      <ToastContainer
        position="top-end"
        className="p-3"
        style={{ marginTop: "125px" }}
      >
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  );
}
