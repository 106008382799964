import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SuccessPage.css"; // Import the custom CSS file

const SuccessPage = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(12);

  useEffect(() => {
    // Update the countdown every second
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // Redirect after 15 seconds
    const timer = setTimeout(() => {
      navigate("/edit-your-speedrent-profile?tab=my-bookings");
    }, 12000); // 15000 ms = 15 seconds

    // Cleanup the interval and timer on component unmount
    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [navigate]);

  return (
    <div className="success-page-container px-3">
      <div className="row justify-content-center align-items-center vh-100">
        <div className="col-12 col-md-6 text-center">
          <div className="icon-container">
            <i className="fa-solid fa-credit-card success-iconnn"></i>
            <i className="fa-solid fa-check success-tickkk"></i>
          </div>
          <h1 className="thank-you-text">Thank You!</h1>
          <p className="payment-success-text">Payment done successfully</p>
          <p className="redirect-text">
            You will be redirected to the Bookings Page shortly or click here to{" "}
            <a href="/home" className="home-link">
              return to the home page
            </a>
            <span className="countdown"> ({seconds}) </span>
          </p>
          <button
            className="rounded px-4 py-2 home-sp"
            onClick={() => navigate("/home")}
          >
            Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
