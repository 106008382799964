import React from "react";
import { Button } from "react-bootstrap";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import HeroFooter from "../hero-footer/HeroFooter";
import { useNavigate } from "react-router-dom";

const RefundCancellation = () => {
  const navigate = useNavigate();
  return (
    <>
      <HeroNavBar showCenterNav={false}/>
      <div className="text-start container my-5 col-lg-9 col-md-10 col-11">
        <div className="my-3 d-flex">
          <Button
            className="rounded-5"
            style={{ backgroundColor: "#000000", border: "none" }}
            onClick={() => navigate("/home")}
          >
            <i class="fa-solid fa-lg fa-arrow-left"></i>
          </Button>
        </div>
        <div>
          <h3 className="text-center">
            <u>Refund & Cancellations</u>
          </h3>
          <br />
          <h4>Payment</h4>
          1.Full payment should be made in advance before reserving and renting
          your selected bikes.
          <br />
          <br />
          2.Any delay in payment will not be accepted and the bikes will be
          handed over to you only after the complete amount is paid.
          <br />
          <br />
          3.We ensure that hirers receive their paid receipts if no amount is
          due. Do not pay any amount till you receive your paid receipts.
          <br />
          <br />
          4.The bike remains in your custody till the rental period and should
          be safely returned back after use.
          <br />
          <br />
          5.If you buy the bike's insurance coverage then in case of any theft
          you will not be subjected to fine.
          <br />
          <br />
          6.Daily bookings are for 24 hours (except some we rent on 12 hours)
          <br />
          <br />
          7.Hourly bookings are minimum of 5 hours.
          <br />
          <br />
          <h4>Security Deposit</h4>
          1.Hirers are required to pay a specific amount as security deposit.
          This security deposit is collected so that if there is any damage
          caused to the vehicle then the amount can be depreciated from your
          security deposit.
          <br />
          <br />
          2.On safely returning back the hired bike, the entire safety deposit
          amount is handed back to the customer.
          <br />
          <br />
          3.In case there is any damage brought to the bike then the amount is
          deducted from your security deposit and the balance amount is returned
          back.
          <br />
          <br />
          4.This amount paid by you is refundable and is entitled to adjustments
          only under certain specific cases.
          <br />
          <br />
          5.All the important documents of the bike are provided to customers as
          soon as they fulfill all the requirements and agree to all the terms
          and conditions.
          <br />
        </div>
      </div>
      <HeroFooter />
    </>
  );
};

export default RefundCancellation;
