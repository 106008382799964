import React, { useState, useEffect } from "react";
import "./KycStep.css";
import { useNavigate } from "react-router-dom";
import { Form, Toast, ToastContainer, Spinner } from "react-bootstrap";
import kycleftimg from "../../assets/kyc-left-img.png";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import fullsizelogo from "../../assets/full-size-logo.png";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import axios from "axios";

export default function KycProcess() {
  const [currentStep, setCurrentStep] = useState(1);

  // State to track if the image is loaded (for image display in the sidebar)
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // States for Step 1 (Aadhar)
  const [aadharNumber, setAadharNumber] = useState("");
  const [frontAadharPhoto, setFrontAadharPhoto] = useState(null);
  const [backAadharPhoto, setBackAadharPhoto] = useState(null);
  const [frontAadharPhotoPreview, setFrontAadharPhotoPreview] = useState(null);
  const [backAadharPhotoPreview, setBackAadharPhotoPreview] = useState(null);
  const [isLoadingFrontAadhar, setIsLoadingFrontAadhar] = useState(false);
  const [isLoadingBackAadhar, setIsLoadingBackAadhar] = useState(false);

  // States for Step 2 (License)
  const [licenseNumber, setLicenseNumber] = useState("");
  const [licensePhoto, setLicensePhoto] = useState(null);
  const [licensePhotoPreview, setLicensePhotoPreview] = useState(null);
  const [isLoadingLicense, setIsLoadingLicense] = useState(false);

  // States for Step 3 (Selfie)
  // const [selfiePhoto, setSelfiePhoto] = useState(null);
  // const [selfiePhotoPreview, setSelfiePhotoPreview] = useState(null);
  // const [isLoadingSelfie, setIsLoadingSelfie] = useState(false);

  // States for Toast notification
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const navigate = useNavigate();

  // States to save data from each step
  const [savedData, setSavedData] = useState({
    aadharNumber: "",
    frontAadharPhoto: "",
    backAadharPhoto: "",
    licenseNumber: "",
    licensePhoto: "",
    selfiePhoto: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");

  // Fetch user info using phone number from localStorage

  useEffect(() => {
    // Check login status and redirect if not logged in
    const loggedInStatus = localStorage.getItem("success-loggedin");
    if (loggedInStatus === "false" || !loggedInStatus) {
      navigate("/home");
      return;
    }

    // Fetch user info if logged in
    const fetchUserInfo = async () => {
      try {
        const phoneNumber = localStorage.getItem("phone-number");
        if (phoneNumber) {
          const response = await axios.post(
            "https://speedrent.in/v2Apis/production/login/getUserInfo.php",
            new URLSearchParams({
              api_key: "Pavan@2024",
              number: phoneNumber,
            })
          );
          const userData = response.data.data;
          if (userData && userData.adhaar) {
            //   setToastMessage(`Welcome, ${userData.name}!`);
            //   setShowToast(true);
            navigate("/home");
          }
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, [navigate]);

  // Function to convert image to Base64
  const convertToBase64 = (file, setPreview, setLoading, key) => {
    const reader = new FileReader();
    setLoading(true);
    reader.onloadend = () => {
      const base64String = reader.result.split(",")[1]; // Extract only the Base64 part
      //   console.log("Base64 Image String:", base64String);
      setPreview(`data:image/*;base64,${base64String}`); // Add back the prefix for preview
      setLoading(false);
    };
    reader.readAsDataURL(file);
  };

  // Handlers for Aadhar front and back photo upload
  const handleFrontAadharPhotoChange = (e) => {
    const file = e.target.files[0];
    setFrontAadharPhoto(file);
    if (file) {
      convertToBase64(
        file,
        setFrontAadharPhotoPreview,
        setIsLoadingFrontAadhar
      );
    }
  };

  const handleBackAadharPhotoChange = (e) => {
    const file = e.target.files[0];
    setBackAadharPhoto(file);
    if (file) {
      convertToBase64(file, setBackAadharPhotoPreview, setIsLoadingBackAadhar);
    }
  };

  const handleDeleteFrontAadharPhoto = () => {
    setFrontAadharPhoto(null);
    setFrontAadharPhotoPreview(null);
  };

  const handleDeleteBackAadharPhoto = () => {
    setBackAadharPhoto(null);
    setBackAadharPhotoPreview(null);
  };

  // Handler for License photo upload
  const handleLicensePhotoChange = (e) => {
    const file = e.target.files[0];
    setLicensePhoto(file);
    if (file) {
      convertToBase64(file, setLicensePhotoPreview, setIsLoadingLicense);
    }
  };

  const handleDeleteLicensePhoto = () => {
    setLicensePhoto(null);
    setLicensePhotoPreview(null);
  };

  // Handler for Selfie photo upload
  // const handleSelfiePhotoChange = (e) => {
  //   const file = e.target.files[0];
  //   setSelfiePhoto(file);
  //   if (file) {
  //     convertToBase64(file, setSelfiePhotoPreview, setIsLoadingSelfie);
  //   }
  // };

  // const handleDeleteSelfiePhoto = () => {
  //   setSelfiePhoto(null);
  //   setSelfiePhotoPreview(null);
  // };

  // Handle the step transition
  const handleNext = () => {
    let errorMessage = "";

    if (currentStep === 1) {
      if (!aadharNumber) {
        errorMessage = "Please enter your Aadhar number.";
      } else if (!frontAadharPhoto) {
        errorMessage = "Please upload the front side of your Aadhar card.";
      } else if (!backAadharPhoto) {
        errorMessage = "Please upload the back side of your Aadhar card.";
      } else {
        // Save data for Step 1
        setSavedData((prevData) => ({
          ...prevData,
          aadharNumber,
          frontAadharPhoto: frontAadharPhotoPreview
            ? frontAadharPhotoPreview.split(",")[1]
            : "",
          backAadharPhoto: backAadharPhotoPreview
            ? backAadharPhotoPreview.split(",")[1]
            : "",
        }));
        setCurrentStep((prevStep) => prevStep + 1);
        return; // Exit the function if no error
      }
    } else if (currentStep === 2) {
      if (!licenseNumber) {
        errorMessage = "Please enter your Driving License number.";
      } else if (!licensePhoto) {
        errorMessage = "Please upload a picture of your Driving License.";
      } else {
        // Save data for Step 2
        setSavedData((prevData) => ({
          ...prevData,
          licenseNumber,
          licensePhoto: licensePhotoPreview
            ? licensePhotoPreview.split(",")[1]
            : "",
        }));
        handleSubmit();
        // setCurrentStep((prevStep) => prevStep + 1);
        return; // Exit the function if no error
      }
    }

    // If there's an error, show the toast
    if (errorMessage) {
      setToastMessage(errorMessage);
      setShowToast(true);
    }
  };

  const handleSubmit = async () => {
    if (
      aadharNumber &&
      frontAadharPhoto &&
      backAadharPhoto &&
      licenseNumber &&
      licensePhoto
    ) {
      setIsSubmitting(true);
      setSubmitError("");

      try {
        const phoneNumber = localStorage.getItem("phone-number");
        const response = await axios.post(
          "https://speedrent.in/v2Apis/production/login/completeKyc.php",
          new URLSearchParams({
            api_key: "Pavan@2024",
            number: phoneNumber,
            adhaar: aadharNumber,
            adhaarImage1: frontAadharPhotoPreview.split(",")[1],
            adhaarImage2: backAadharPhotoPreview.split(",")[1],
            driving: licenseNumber,
            dlImage: licensePhotoPreview.split(",")[1],
          })
        );

        if ((response.data = "success")) {
          navigate("/edit-your-speedrent-profile?tab=kyc");
        } else {
          setSubmitError("Error submitting KYC data. Please try again.");
        }
      } catch (error) {
        setSubmitError("An error occurred. Please try again.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setSubmitError("Please complete all steps before submitting.");
    }
  };

  return (
    <div className="row vh-100 overflow-x-hidden kyc-step-container ms-lg-2 ms-1">
      <div className="d-none d-lg-block">
        <Navbar className="position-absolute w-100">
          <Container>
            <Navbar.Brand href="/home">
              <img
                src={fullsizelogo}
                width="90"
                height="95"
                className="d-inline-block align-top mt-3"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end mb-5">
              <div className="position-relative d-flex justify-content-end">
                {currentStep === 1 && (
                  <button
                    className="px-5 skip-btn-kyc rounded py-1"
                    onClick={() => navigate("/home")}
                  >
                    SKIP
                  </button>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <div className="col-lg-6 col-xl-6 col-md-12 d-sm-flex d-none justify-content-center">
        <div className="image-container my-lg-auto align-items-end d-lg-flex d-none pb-lg-5">
          <img
            src={kycleftimg}
            height="275px"
            alt="alternative"
            onLoad={() => setIsImageLoaded(true)}
          />
        </div>
      </div>
      <div className="d-flex d-lg-none">
        <Navbar className="fixed-top nav-mobile-kyc mx-0">
          <Container>
            <Navbar.Brand href="/home">
              <img
                src={fullsizelogo}
                width="50"
                height="57"
                className="d-inline-block align-top ps-1"
                alt="React Bootstrap logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
              <div className="position-relative d-flex justify-content-end">
                {currentStep === 1 && (
                  <button
                    className="skip-btn-kyc-inside me-3 py-1 px-3 rounded"
                    onClick={() => navigate("/home")}
                  >
                    SKIP
                  </button>
                )}
              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="col-lg-6 col-xl-6 mx-auto text-start px-lg-5 mt-lg-0 mt-md-4 mt-5 py-lg-0 py-5">
        <div className="bg-light rounded-4 shadow py-lg-5 px-3 py-4 mx-md-5 mx-sm-4 mx-lg-3 px-md-5 my-lg-0">
          {currentStep === 1 && (
            <div>
              <div className="pb-1">
                <h4>KYC - Step 1/2</h4>
                <p style={{ fontSize: "14px" }}>
                  Please provide your Aadhaar details for verification purposes.
                </p>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <p style={{ fontSize: "14px" }} className="m-0 p-0">
                      Aadhar Number
                    </p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="formAadharNumber"
                    value={aadharNumber}
                    placeholder="Enter your Aadhar number"
                    onChange={(e) => setAadharNumber(e.target.value)}
                    required
                  />
                </Form.Group>

                <div className="row">
                  <Form.Group className="mb-3 col-6">
                    <Form.Label>
                      <p style={{ fontSize: "14px" }} className="m-0 p-0">
                        Aadhar Card (Front Side)
                      </p>
                    </Form.Label>
                    <div className="upload-box-front d-flex align-items-center justify-content-center position-relative">
                      {isLoadingFrontAadhar ? (
                        <Spinner animation="border" />
                      ) : frontAadharPhotoPreview ? (
                        <>
                          <img
                            src={frontAadharPhotoPreview}
                            alt="Front Aadhar Preview"
                            className="uploaded-image-front"
                          />
                          <button
                            type="button"
                            className="delete-icon-front shadow-sm px-2 py-1"
                            onClick={handleDeleteFrontAadharPhoto}
                          >
                            <FaTrashAlt color="red" />
                          </button>
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="frontAadharPhotoInput"
                            className="plus-icon-front"
                          >
                            <FaPlus color="#ccc" size="24px" />
                          </label>
                          <Form.Control
                            type="file"
                            id="frontAadharPhotoInput"
                            accept="image/*"
                            onChange={handleFrontAadharPhotoChange}
                            className="d-none"
                          />
                        </>
                      )}
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-3 col-6">
                    <Form.Label>
                      <p style={{ fontSize: "14px" }} className="m-0 p-0">
                        Aadhar Card (Back Side)
                      </p>
                    </Form.Label>
                    <div className="upload-box-back d-flex align-items-center justify-content-center position-relative">
                      {isLoadingBackAadhar ? (
                        <Spinner animation="border" />
                      ) : backAadharPhotoPreview ? (
                        <>
                          <img
                            src={backAadharPhotoPreview}
                            alt="Back Aadhar Preview"
                            className="uploaded-image-back"
                          />
                          <button
                            type="button"
                            className="delete-icon-back shadow-sm px-2 py-1"
                            onClick={handleDeleteBackAadharPhoto}
                          >
                            <FaTrashAlt color="red" />
                          </button>
                        </>
                      ) : (
                        <>
                          <label
                            htmlFor="backAadharPhotoInput"
                            className="plus-icon-back"
                          >
                            <FaPlus color="#ccc" size="24px" />
                          </label>
                          <Form.Control
                            type="file"
                            id="backAadharPhotoInput"
                            accept="image/*"
                            onChange={handleBackAadharPhotoChange}
                            className="d-none"
                          />
                        </>
                      )}
                    </div>
                  </Form.Group>
                </div>
                <button
                  type="button"
                  className="w-100 py-lg-2 py-3 kyc-submit-btn rounded-3"
                  onClick={handleNext}
                >
                  NEXT
                </button>
              </Form>
            </div>
          )}

          {currentStep === 2 && (
            <div>
              <div className="pb-1">
                <h4>KYC - Step 2/2</h4>
                <p style={{ fontSize: "14px" }}>
                  Please provide your License details for verification purposes.
                </p>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <p style={{ fontSize: "14px" }} className="m-0 p-0">
                      Driving License Number
                    </p>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    id="formLicenseNumber"
                    value={licenseNumber}
                    placeholder="Enter your Driving License number"
                    onChange={(e) => setLicenseNumber(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    <p style={{ fontSize: "14px" }} className="m-0 p-0">
                      Driving License Picture
                    </p>
                  </Form.Label>
                  <div className="upload-box-license d-flex align-items-center justify-content-center position-relative">
                    {isLoadingLicense ? (
                      <Spinner animation="border" />
                    ) : licensePhotoPreview ? (
                      <>
                        <img
                          src={licensePhotoPreview}
                          alt="License Preview"
                          className="uploaded-image-license"
                        />
                        <button
                          type="button"
                          className="delete-icon-license shadow-sm px-2 py-1"
                          onClick={handleDeleteLicensePhoto}
                        >
                          <FaTrashAlt color="red" />
                        </button>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="licensePhotoInput"
                          className="plus-icon-license"
                        >
                          <FaPlus color="#ccc" size="24px" />
                        </label>
                        <Form.Control
                          type="file"
                          id="licensePhotoInput"
                          accept="image/*"
                          onChange={handleLicensePhotoChange}
                          className="d-none"
                        />
                      </>
                    )}
                  </div>
                </Form.Group>

                <button
                  type="button"
                  className="w-100 py-lg-2 py-3 kyc-submit-btn-step-two rounded-3"
                  onClick={handleNext}
                >
                  Submit
                </button>
              </Form>
            </div>
          )}

          {/* {currentStep === 3 && (
            <div>
              <div className="pb-1">
                <h4>KYC - Step 3/3</h4>
                <p style={{ fontSize: "14px" }}>
                  Please provide your Selfie for verification purposes.
                </p>
              </div>
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Label>
                    <p style={{ fontSize: "14px" }} className="m-0 p-0">
                      Selfie Photo
                    </p>
                  </Form.Label>
                  <div className="upload-box-selfie d-flex align-items-center justify-content-center position-relative">
                    {isLoadingSelfie ? (
                      <Spinner animation="border" />
                    ) : selfiePhotoPreview ? (
                      <>
                        <img
                          src={selfiePhotoPreview}
                          alt="Selfie Preview"
                          className="uploaded-image-selfie"
                        />
                        <button
                          type="button"
                          className="delete-icon-selfie shadow-sm px-2 py-1"
                          onClick={handleDeleteSelfiePhoto}
                        >
                          <FaTrashAlt color="red" />
                        </button>
                      </>
                    ) : (
                      <>
                        <label
                          htmlFor="selfiePhotoInput"
                          className="plus-icon-selfie"
                        >
                          <FaPlus color="#ccc" size="24px" />
                        </label>
                        <Form.Control
                          type="file"
                          id="selfiePhotoInput"
                          accept="image/*"
                          onChange={handleSelfiePhotoChange}
                          className="d-none"
                        />
                      </>
                    )}
                  </div>
                </Form.Group>

                <button
                  type="submit"
                  className="w-100 py-lg-2 py-3 kyc-submit-btn-step-three rounded-3"
                >
                  SUBMIT
                </button>
              </Form>
            </div>
          )} */}
        </div>
      </div>

      {/* Toast for User Greeting */}
      <ToastContainer position="bottom-center" className="p-3">
        {/* General Error Toast */}
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3500}
          autohide
        >
          <Toast.Body>
            <strong className="me-auto text-danger">
              <span style={{ fontWeight: "600" }}>{toastMessage}</span>
            </strong>
          </Toast.Body>
        </Toast>

        {/* Submit Error Toast */}
        <Toast
          onClose={() => setSubmitError("")}
          show={!!submitError}
          delay={3500}
          autohide
        >
          <Toast.Body>
            <strong className="me-auto text-danger">
              <span style={{ fontWeight: "600" }}>{submitError}</span>
            </strong>
          </Toast.Body>
        </Toast>
      </ToastContainer>

      {isSubmitting && (
        <div className="loader-overlay">
          <Spinner animation="border" />
        </div>
      )}
    </div>
  );
}
