import React from "react";
import { Modal, Button } from "react-bootstrap";
import DateTimePicker from "./DateTimePicker";

const StartDateModal = ({ showStartModal, handleCloseModal, handleApply }) => (
  <Modal show={showStartModal} onHide={handleCloseModal}>
    <Modal.Body>
      <DateTimePicker
        mode="pickup"
        onApply={handleApply}
        handleCloseModal={handleCloseModal}
      />
    </Modal.Body>
  </Modal>
);

export default StartDateModal;
