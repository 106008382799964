import React, { useState, useEffect, useRef } from "react";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import "./EditProfile.css";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import errImageBike from "../../assets/login-left-img.png";
import BookingDetailsModal from "./BookingDetailsModal";

export default function EditProfile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [initialName, setInitialName] = useState("");
  const [initialEmail, setInitialEmail] = useState("");
  const [bookings, setBookings] = useState([]);
  const [loadingBookings, setLoadingBookings] = useState(true);
  const [bikeDetails, setBikeDetails] = useState({});
  const [kycData, setKycData] = useState(null);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const defaultActiveTab =
    searchParams.get("tab") === "kyc"
      ? "second"
      : searchParams.get("tab") === "my-bookings"
      ? "third"
      : "first";

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phone-number");
    if (!storedPhoneNumber) {
      navigate("/home");
    } else {
      setPhoneNumber(storedPhoneNumber);

      const fetchUserDetails = async () => {
        try {
          console.log("Fetching user details...");

          const response = await axios.post(
            "https://speedrent.in/v2Apis/production/login/getUserInfo.php",
            new URLSearchParams({
              api_key: "Pavan@2024",
              number: storedPhoneNumber,
            })
          );
          const userData = response.data.data;
          if (userData) {
            setName(userData.name || "");
            setEmail(userData.email || "");
            setInitialName(userData.name || "");
            setInitialEmail(userData.email || "");
            setKycData({
              adhaar: userData.adhaar || "",
              adhaarImage: userData.adhaarImage || "",
              driving: userData.driving || "",
              drivingImage: userData.drivingImage || "",
              image: userData.image || "",
            });
          }
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
      };
      fetchUserDetails();
    }
  }, [navigate]);

  const handleCardClick = (booking) => {
    setSelectedBooking(booking);
    setShowModal(true);
  };

  useEffect(() => {
    if (isEditingName && nameRef.current) {
      nameRef.current.focus();
    }
  }, [isEditingName]);

  useEffect(() => {
    if (isEditingEmail && emailRef.current) {
      emailRef.current.focus();
    }
  }, [isEditingEmail]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        console.log("Fetching bookings...");
        const response = await axios.post(
          "https://speedrent.in/v2Apis/production/Bookings/getCustomerBookings.php",
          new URLSearchParams({
            api_key: "Pavan@2024",
            number: localStorage.getItem("phone-number"),
          })
        );
        const bookingData = response.data.data || [];
        const bikeDetailsPromises = bookingData.map(async (booking) => {
          try {
            const bikeResponse = await axios.post(
              "https://speedrent.in/v2Apis/production/Bookings/getBikeDetails.php",
              new URLSearchParams({
                api_key: "Pavan@2024",
                bikeNumber: booking.bikeNumber,
              })
            );
            return {
              bookingId: booking.bookingId,
              modelName: bikeResponse.data.data?.modelName || "NA",
              kmLimit: bikeResponse.data.data?.kmLimit || "NA",
            };
          } catch (error) {
            console.error(
              `Error fetching bike details for ${booking.bikeNumber}:`,
              error
            );
            return {
              bookingId: booking.bookingId,
              modelName: "NA",
              kmLimit: "NA",
            };
          }
        });
        const fetchedBikeDetails = await Promise.all(bikeDetailsPromises);
        const bookingsWithDetails = bookingData.map((booking) => {
          const bikeDetail = fetchedBikeDetails.find(
            (detail) => detail.bookingId === booking.bookingId
          );
          return {
            ...booking,
            modelName: bikeDetail.modelName,
            kmLimit: bikeDetail.kmLimit,
          };
        });
        setBookings(bookingsWithDetails);
      } catch (error) {
        console.error("Error fetching bookings:", error);
      } finally {
        setLoadingBookings(false);
      }
    };
    fetchBookings();
  }, []);

  const handleEditName = () => {
    setIsEditingName(true);
  };

  const handleEditEmail = () => {
    setIsEditingEmail(true);
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://speedrent.in/v2Apis/production/login/register.php",
        new URLSearchParams({
          api_key: "Pavan@2024",
          number: phoneNumber,
          name: name,
          email: email,
        })
      );
      console.log("Save response:", response.data);
      setShowToast(true);
      setInitialName(name);
      setInitialEmail(email);
    } catch (error) {
      console.error("Error saving user details:", error);
    }
    setIsEditingName(false);
    setIsEditingEmail(false);
  };

  const hasChanges = () => {
    return name !== initialName || email !== initialEmail;
  };

  return (
    <>
      <div className="vh-100">
        <HeroNavBar showCenterNav={false} />
        <div className="p-lg-5 p-md-4 p-3">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
          >
            <Row>
              <Col lg={3}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <span className="fs-5">General</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <span className="fs-5">KYC</span>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="third">
                      <span className="fs-5">My Bookings</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9}>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="first"
                    className="py-lg-5 py-md-4 border tab-first-container py-sm-3 py-3 text-start px-lg-5 px-md-4 px-3 rounded mt-lg-0 mt-sm-0 mt-4"
                  >
                    <span>
                      <h2>User Information</h2>
                    </span>
                    <hr />
                    <Form onSubmit={handleSave}>
                      <div className="row mb-3">
                        <Form.Group
                          className="col-4 pe-1"
                          controlId="formBasicCountryCode"
                        >
                          <Form.Label>
                            <p style={{ fontSize: "14px" }} className="m-0 p-0">
                              Mobile
                            </p>
                          </Form.Label>
                          <Form.Control
                            className="text-end no-field-disabled"
                            disabled
                            value={"+91"}
                          />
                        </Form.Group>
                        <Form.Group
                          className="col-8 ps-1"
                          controlId="formBasicPhone"
                        >
                          <Form.Label>
                            <p style={{ fontSize: "14px" }} className="m-0 p-0">
                              &nbsp;
                            </p>
                          </Form.Label>
                          <Form.Control
                            disabled
                            className="text-start no-field-disabled"
                            placeholder="Please login to register"
                            value={phoneNumber}
                          />
                        </Form.Group>
                      </div>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Name*</Form.Label>
                        <span className="d-flex justify-content-between">
                          <Form.Control
                            type="text"
                            value={name}
                            className="fonm-mains-prof"
                            placeholder="Please enter your full name"
                            onChange={(e) => setName(e.target.value)}
                            required
                            disabled={!isEditingName}
                            ref={nameRef}
                          />
                          <button
                            type="button"
                            className="ms-2 rounded edit-rpofile-btn"
                            onClick={handleEditName}
                          >
                            <i className="fa-solid fa-pencil fa-sm"></i>edit
                          </button>
                        </span>
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Email address</Form.Label>
                        <span className="d-flex justify-content-between">
                          <Form.Control
                            type="email"
                            value={email}
                            className="fonm-mains-prof"
                            placeholder="Please enter your email address"
                            onChange={(e) => setEmail(e.target.value)}
                            disabled={!isEditingEmail}
                            ref={emailRef}
                          />
                          <button
                            type="button"
                            className="ms-2 rounded edit-rpofile-btn"
                            onClick={handleEditEmail}
                          >
                            <i className="fa-solid fa-pencil fa-sm"></i>edit
                          </button>
                        </span>
                      </Form.Group>
                      {hasChanges() && (isEditingName || isEditingEmail) ? (
                        <button
                          type="submit"
                          className="mt-3 save-changes-btn-profile px-4 py-2"
                        >
                          Save Changes
                        </button>
                      ) : null}
                    </Form>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="second"
                    className="py-lg-5 py-md-4 border tab-second-container py-sm-3 py-3 text-start px-lg-5 px-md-4 px-3 rounded mt-lg-0 mt-sm-0 mt-4"
                  >
                    {kycData?.adhaar ? (
                      <div>
                        <span>
                          <h2>KYC Information</h2>
                        </span>
                        <hr />
                        <div className="mb-3">
                          <Form.Group className="mb-3" controlId="adhaarField">
                            <Form.Label>Adhaar Number</Form.Label>
                            <Form.Control
                              type="text"
                              value={kycData.adhaar}
                              className="fonm-mains-prof"
                              placeholder="Adhaar Number"
                              readOnly
                              disabled
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="drivingLicenseField"
                          >
                            <Form.Label>Driving Licence Number</Form.Label>
                            <Form.Control
                              type="text"
                              value={kycData.driving}
                              className="fonm-mains-prof"
                              placeholder="Driving Licence Number"
                              readOnly
                              disabled
                            />
                          </Form.Group>
                        </div>

                        <span>
                          <h4>Images</h4>
                        </span>
                        <hr />
                        <div className="row mb-3">
                          <div
                            className="col-md-6 col-12 p-1 mb-sm-0 mb-1"
                            style={{ height: "200px" }}
                          >
                            <figure className="h-100">
                              <img
                                src={`https://speedrent.in/adminapp/uploads/${kycData.adhaarImage}`}
                                alt="adhaar-front-image-kyc"
                                className="img-fluid rounded-4 border shadow-sm p-1"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <figcaption className="text-center my-2">
                                Adhaar Front Image
                              </figcaption>
                            </figure>
                          </div>

                          <div
                            className="col-md-6 mt-md-0 mt-5 col-12 p-1"
                            style={{ height: "200px" }}
                          >
                            <figure className="h-100">
                              <img
                                src={`https://speedrent.in/adminapp/uploads/${kycData.adhaar}_back.jpg`}
                                alt="Adhaar Back"
                                className="img-fluid rounded-4 border shadow-sm p-1"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <figcaption className="text-center my-2">
                                Adhaar Back Image
                              </figcaption>
                            </figure>
                          </div>
                        </div>

                        <div className="mb-3 mt-5"></div>
                        <div className="row mb-3">
                          <div
                            className="col-md-6 col-12 p-1 mb-sm-0 mb-1"
                            style={{ height: "200px" }}
                          >
                            <figure className="h-100">
                              <img
                                src={`https://speedrent.in/adminapp/uploads/${kycData.drivingImage}`}
                                alt="Driving Licence"
                                className="img-fluid rounded-4 border shadow-sm p-1"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <figcaption className="text-center my-2">
                                Driving Licence Image
                              </figcaption>
                            </figure>
                          </div>
                          {/* <div
                            className="col-md-6 mt-md-0 mt-5 col-12 p-1"
                            style={{ height: "200px" }}
                          >
                            <figure className="h-100">
                              <img
                                src={`https://speedrent.in/adminapp/uploads/${kycData.image}`}
                                alt="User"
                                className="img-fluid rounded-4 border shadow-sm p-1"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <figcaption className="text-center my-2">
                                Selfie
                              </figcaption>
                            </figure>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <p>
                        KYC information not found for{" "}
                        {localStorage.getItem("phone-number")}
                      </p>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="third" className="py-md-0 py-4">
                    {loadingBookings ? (
                      <p>Loading booking details...</p>
                    ) : bookings.length === 0 ? (
                      <p>
                        No booking details found for{" "}
                        {localStorage.getItem("phone-number")}.
                        <br />
                        Book your bike{" "}
                        <span
                          style={{
                            color: "#ff6600",
                            fontWeight: "600",
                            cursor: "pointer",
                          }}
                          onClick={() => navigate("/bike-booking")}
                        >
                          <u>
                            <i>&gt;&gt;now</i>
                          </u>
                        </span>
                      </p>
                    ) : (
                      <div
                        style={{
                          maxHeight: "75vh",
                          overflowY: "auto",
                          overflowX: "hidden",
                          borderBottom: "0.4px solid #000000",
                          borderRadius: "4px",
                          paddingInline: "2px",
                        }}
                      >
                        <h2 className="text-start">Manage your Bookings</h2>
                        <hr />
                        <Row>
                          {bookings.map((booking, index) => (
                            <Col key={booking.bookingId} md={6} xs={12}>
                              <Card
                                className="mb-3 shadow-sm carddetmodhov"
                                onClick={() => handleCardClick(booking)}
                                style={{ cursor: "pointer" }}
                              >
                                <Card.Header className="shadow-sm d-flex justify-content-between align-items-center card-header-bookings">
                                  <span className="bookingid-card-avails">
                                    {}
                                    {""}
                                    <i>ID: {booking.bookingId || "NA"}</i>
                                  </span>
                                  <div>
                                    <span
                                      className="status-ongoing px-2 py-1 rounded"
                                      style={{
                                        display:
                                          booking.status == 1
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <span className="d-flex align-items-center justify-content-end">
                                        <i className="fa-solid fa-circle fa-bookings-avail me-1"></i>
                                        Ongoing
                                      </span>
                                    </span>
                                    <span
                                      className="status-bookingConfirmed px-2 py-1 rounded"
                                      style={{
                                        display:
                                          booking.status == 2
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <span className="d-flex align-items-center justify-content-end">
                                        <i className="fa-solid fa-circle fa-bookings-avail me-1"></i>
                                        Booking Confirmed
                                      </span>
                                    </span>
                                    <span
                                      className="status-completed px-2 py-1 rounded"
                                      style={{
                                        display:
                                          booking.status == 3
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <span className="d-flex align-items-center justify-content-end">
                                        <i className="fa-solid fa-circle fa-bookings-avail me-1"></i>
                                        Completed
                                      </span>
                                    </span>
                                    <span
                                      className="status-booked-pendingPayment px-2 py-1 rounded"
                                      style={{
                                        display:
                                          booking.status == 4
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      <span className="d-flex align-items-center justify-content-end">
                                        <i className="fa-solid fa-circle fa-bookings-avail me-1"></i>
                                        Booked, Pending Payment
                                      </span>
                                    </span>
                                    <span
                                      style={{
                                        display:
                                          booking.status == undefined
                                            ? "block"
                                            : "none",
                                      }}
                                    >
                                      NA
                                    </span>
                                  </div>
                                </Card.Header>
                                <Card.Body
                                  className="d-flex shadow justify-content-center"
                                  style={{ minHeight: "125px" }}
                                >
                                  <span className="row d-flex align-items-center px-2">
                                    <img
                                      src={`https://speedrent.in/adminapp/uploads/${booking.bikeNumber}.png`}
                                      onError={(e) => {
                                        e.target.src = errImageBike;
                                      }}
                                      alt="Bike"
                                      style={{
                                        width: 100,
                                        height: 100,
                                        objectFit: "contain",
                                        padding: "2px",
                                      }}
                                      className="col-4 border rounded"
                                    />

                                    <div className="d-flex flex-column justify-content-between col-5 text-start">
                                      <div
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          fontSize: "medium",
                                        }}
                                      >
                                        <strong>{booking.modelName}</strong>
                                      </div>

                                      <div style={{ fontSize: "small" }}>
                                        <i
                                          class="fa-solid fa-road-circle-check fa-sm me-1"
                                          style={{ color: "#ff6600" }}
                                        ></i>
                                        {booking.kmLimit} km free
                                      </div>
                                    </div>
                                    <div className="ms-auto col-3 text-success">
                                      <strong>
                                        ₹{booking.bookingAmount || "NA"}
                                      </strong>
                                    </div>
                                  </span>
                                </Card.Body>
                                <Card.Footer className="shadow-sm d-flex justify-content-between date-time-text-avails">
                                  <span>
                                    {booking.pickupDate
                                      ? `${booking.pickupDate}, ${booking.pickupTime}`
                                      : "NA"}
                                  </span>
                                  <span>&#8594;</span>
                                  <span>
                                    {booking.dropDate
                                      ? `${booking.dropDate}, ${booking.dropTime}`
                                      : "NA"}
                                  </span>
                                </Card.Footer>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
        {/* Bootstrap Toast */}
        <ToastContainer position="bottom" className="p-3">
          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
            bg="success"
          >
            <Toast.Header>
              <strong className="me-auto">Success</strong>
            </Toast.Header>
            <Toast.Body>Profile updated successfully!</Toast.Body>
          </Toast>
        </ToastContainer>

        {/* Booking Details Modal */}
        <BookingDetailsModal
          show={showModal}
          onHide={() => setShowModal(false)}
          booking={selectedBooking}
        />
      </div>
    </>
  );
}
