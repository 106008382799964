import React from "react";
import { Button } from "react-bootstrap";
import HeroNavBar from "../hero-nav-bar/HeroNavBar";
import HeroFooter from "../hero-footer/HeroFooter";
import { useNavigate } from "react-router-dom";

const CompanyPolicy = () => {
  const navigate = useNavigate();
  return (
    <>
     {/* Pass showCenterNav={false} to hide the center nav */}
      <HeroNavBar showCenterNav={false}/>
      <div className="text-start container my-5 col-lg-9 col-md-10 col-11">
        <div className="my-3 d-flex">
          <Button
            className="rounded-5"
            style={{ backgroundColor: "#000000", border: "none" }}
            onClick={() => navigate("/home")}
          >
            <i class="fa-solid fa-lg fa-arrow-left"></i>
          </Button>
        </div>
        <div>
          <h3 className="text-center">
            <u>Privacy Policy</u>
          </h3>
          <br />
          Speed Rent (“us”, “we”, or “our”) operates the https://speedrent.in/
          Website/App and the Speed Rent mobile application (the “Service”).
          <br />
          <br />
          Speed Rent LLP is a company duly registered and incorporated under the
          companies Act,2008. It is registered whose address is at no. E-43/1,
          Pocket D, Okhla Phase II, Okhla Industrial Area, New Delhi, Delhi
          110020.
          <br />
          <br />
          Speed Rent LLP owns and operates https://speedrent.in/ that is a
          Website / App for booking two wheelers on rent/lease for short term as
          well as for long term.
          <br />
          <br />
          This document is published and shall be construed in accordance with
          the provisions of the Information Technology (reasonable security
          practices and procedures and sensitive personal data of information)
          rules, 2011 under Information Technology Act, 2000; that require
          publishing of the Privacy Policy for collection, use, storage and
          transfer of sensitive personal data or information.
          <br />
          <br />
          Please read this Privacy Policy carefully by using the Website / App,
          you indicate that you understand, agree and consent to this Privacy
          Policy. If you do not agree with the terms of this Privacy Policy,
          please do not use this Website / App.
          <br />
          <br />
          By providing us your Information or by making use of the facilities
          provided by the Website / App, You hereby consent to the collection,
          storage, processing and transfer of any or all of Your Personal
          Information and Non-Personal Information by us as specified under this
          Privacy Policy. You further agree that such collection, use, storage
          and transfer of Your Information shall not cause any loss or wrongful
          gain to you or any other person.
          <br />
          <br />
          <h4>
            By registering at Gobikes you agree to the following terms and
            conditions:
          </h4>
          1. Speed Rent LLP operates https://speedrent.in/ which is a platform
          for renting two-wheelers. In order to book two-wheelers from our
          platform, Customers need to signup or log in by providing details like
          Full name, Mobile number, Email id, Password, and Emergency contact
          number along with the relationship, and blood type. By registering at
          Speed Rent, You accept all the Terms and conditions and the privacy
          policy and you take full responsibility for every activities occurred
          under your name.
          <br />
          <br />
          2. We expect to keep your personal data safe and suggest you not to
          share it with anyone. You will not pretend to be someone else or
          create fake accounts or multiple accounts.
          <br />
          <br />
          3. Information Collection And Use
          <br />
          <br />
          We collect several different types of information for various purposes
          to provide and improve our Service to you.
          <br />
          <br />
          4. Types of Data Collected
          <br />
          <br />
          Personal Data
          <br />
          <br />
          While using our Service, we may ask you to provide us with certain
          personally identifiable information that can be used to contact or
          identify you (“Personal Data”). Personally identifiable information
          may include, but is not limited to:
          <br />
          <br />
          i. Email address
          <br />
          <br />
          ii. First name and last name
          <br />
          <br />
          iii. Phone number
          <br />
          <br />
          iv. Employer
          <br />
          <br />
          v. College Name
          <br />
          <br />
          vi. Address, State, Province, ZIP/Postal code, City
          <br />
          <br />
          vii. Cookies and Usage Data
          <br />
          <br />
          viii. Usage Data
          <br />
          <br />
          5. Unique identifiers (such as membership number or email addresses)
          are collected from Website / App visitors to verify the user's
          identity and for use as account numbers in our record system.
          Financial information (such as their bank account or credit card
          numbers) is collected and used where appropriate to bill the customer
          for services.
          <br />
          <br />
          i. You can update your personal details by accessing your account
          Profile page and making any necessary changes; this will update your
          details across all services that you have or will use at Speed Rent.
          <br />
          <br />
          ii. Speed Rent will not use or share the Personally Identifiable
          Information collected on our Website / App in ways unrelated to the
          purpose for which you provided the information without providing you
          with a choice whether to permit any such unrelated uses. Speed Rent
          does not sell, trade, or rent to others the Personally Identifiable
          Information we collect.
          <br />
          <br />
          iii. Speed Rent seeks to ensure that your information and preferences
          are accurate and complete and ensure that we don't hold personal
          information any longer than is necessary. If you wish to view or
          change your information, where possible you may do this online.
          Alternatively by sending an email with your name, full mailing
          address, email address, and relevant information about subscriptions
          and registrations you have with us to the customer support team at
          speedrentbikes@gmail.com.
          <br />
          <br />
          iv.We collect Personally Identifiable Information when you ask to
          receive e-newsletters, promotional emails, and other information. We
          use this information to provide you with the information you
          requested. You may opt-out of receiving future mailings dropping us an
          email at speedrentbikes@gmail.com.
          <br />
          <br />
          v.Our primary goal in collecting information is to provide customers
          with a better service and to provide all visitors a smooth, efficient,
          and personalised experience while using our Website / App. If you
          contact us, we may keep a record of your correspondence or comments.
          We use this information to help us provide better service in the event
          that you contact us again.
          <br />
          <br />
          vi.To use some of our services such as the 'shopping cart' and the log
          on process, we use cookies. A 'cookie' is a small data file created by
          your web browser and stored on your computer's hard drive. Some of the
          information collected also permits us to analyse traffic patterns on
          our Website / App. This can enable us over time to provide you with a
          better experience on our Website / App by improving the content and
          making it easier to use.
          <br />
          <br />
          vii.These terms and/or your use of the Website / App shall be governed
          by and construed in accordance with Indian law and the Indian Courts
          shall have exclusive jurisdiction over any dispute which may arise.
          <br />
          <br />
          viii.Speed Rent acknowledges your trust and is committed to protecting
          the information you provide. To prevent unauthorised access, maintain
          accuracy, and ensure proper use of information, we employ physical,
          electronic, and managerial processes to safeguard and secure the
          information we collect online. Our site has security measures in place
          to protect against the loss, misuse and alteration of the information
          under our control. These include but are not limited to a secured
          database containing personal data. The database is located off the
          main web server and is not accessible directly. Whilst we will use all
          reasonable efforts to safeguard your personal data, you acknowledge
          that the use of the internet is not entirely secure and for this
          reason we cannot guarantee the security or integrity of any personal
          data in transit from you or to you via the internet.
          <br />
          <br />
          ix.If you breach these or any of our other terms and conditions we
          reserve the right to close your account, if we do so, we may close all
          accounts you have open in your name.
          <br />
          <br />
          <h4>USER INFORMATION</h4>
          6.To avail certain services on our App, users are required to provide
          certain information for the registration process namely:
          <br />
          <br />
          1. Name
          <br />
          <br />
          2. Email address
          <br />
          <br />
          3. Sex
          <br />
          <br />
          4. Age
          <br />
          <br />
          5. PIN code
          <br />
          <br />
          6. Credit card or Debit card details
          <br />
          <br />
          7. Medical records and history
          <br />
          <br />
          8. Sexual orientation
          <br />
          <br />
          9. Biometric information
          <br />
          <br />
          10. Password
          <br />
          <br />
          11. Occupation, interests, and the like
          <br />
          <br />
          The Information as supplied by the users enables us to improve our
          sites and provide you the most user-friendly experience. All required
          information is service dependent and we may use the above said user
          information to, maintain, protect, and improve its services (including
          advertising services) and for developing new services Such information
          will not be considered as sensitive if it is freely available and
          accessible in the public domain or is furnished under the Right to
          Information Act, 2005 or any other law for the time being in force.
          <br />
          <br />
          <h4>COOKIES</h4>
          To improve the responsiveness of the sites for our users, we may use
          “cookies” or similar electronic tools to collect information to assign
          each visitor a unique, random number as a User Identification (User
          ID) to understand the user's individual interests using the Identified
          Computer. Unless you voluntarily identify yourself (through
          registration, for example), we will have no way of knowing who you
          are, even if we assign a cookie to your computer. The only personal
          information a cookie can contain is information you supply (an example
          of this is when you ask for our Personalised Horoscope). A cookie
          cannot read data off your hard drive. Our advertisers may also assign
          their own cookies to your browser (if you click on their ads), a
          process that we do not control.
          <br />
          <br />
          Our web servers automatically collect limited information about your
          computer's connection to the Internet,including your IP address, when
          you visit our site. (Your IP address is a number that lets computers
          attached to the Internet know where to send you data — such as the web
          pages you view.) Your IP address does not identify you personally. We
          use this information to deliver our web pages to you upon request, to
          tailor our site to the interests of our users, to measure traffic
          within our site and let advertisers know the geographic locations from
          where our visitors come.
          <br />
          <br />
          <h4>INFORMATION SHARING</h4>
          We shares the sensitive personal information to any third party
          without obtaining the prior consent of the user in the following
          limited circumstances:
          <br />
          <br />
          When it is requested or required by law or by any court or
          governmental agency or authority to disclose,for the purpose of
          verification of identity, or for the prevention, detection,
          investigation including cyber incidents, or for prosecution and
          punishment of offences. These disclosures are made in good faith and
          belief that such disclosure is reasonably necessary for enforcing
          these Terms; for complying with the applicable laws and regulations.
          <br />
          <br />
          We proposes to share such information within its group companies and
          officers and employees of such group companies for the purpose of
          processing personal information on its behalf. We also ensure that
          these recipients of such information agree to process such information
          based on our instructions and in compliance with this Privacy Policy
          and any other appropriate confidentiality and security measures.
          <br />
          <br />
          <h4>INFORMATION SECURITY</h4>
          We take appropriate security measures to protect against unauthorized
          access to or unauthorized alteration, disclosure or destruction of
          data. These include internal reviews of our data collection, storage
          and processing practices and security measures, including appropriate
          encryption and physical security measures to guard against
          unauthorized access to systems where we store personal data.
          <br />
          <br />
          All information gathered on our Website / App is securely stored
          within our controlled database. The database is stored on servers
          secured behind a firewall; access to the servers is password-protected
          and is strictly limited. However, as effective as our security
          measures are, no security system is impenetrable. We cannot guarantee
          the security of our database, nor can we guarantee that information
          you supply will not be intercepted while being transmitted to us over
          the Internet. And, of course, any information you include in a posting
          to the discussion areas is available to anyone with Internet access.
          <br />
          <br />
          However the internet is an ever evolving medium. We may change our
          Privacy Policy from time to time to incorporate necessary future
          changes. Of course, our use of any information we gather will always
          be consistent with the policy under which the information was
          collected, regardless of what the new policy maybe.
          <br />
        </div>
      </div>
      <HeroFooter />
    </>
  );
};

export default CompanyPolicy;
