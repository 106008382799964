import React, { useEffect, useState } from "react";
import axios from "axios";
import { Card, Row, Col, Container, Spinner } from "react-bootstrap";
import "./BikeListings.css";
import bikeimg from "../../assets/bengalluru-img.png";
import BikeDetailsModal from "./BikeDetailsModal";

export default function BikeListings({
  pickupDate,
  dropDate,
  branch,
  duration,
  sortByPrice,
  filterByTransmission,
  filterByFuelType,
}) {
  const [bikes, setBikes] = useState([]);
  const [displayedBikes, setDisplayedBikes] = useState(12);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedBike, setSelectedBike] = useState(null);

  useEffect(() => {
    const fetchBikes = async () => {
      try {
        setLoading(true);
        const response = await axios.post(
          "https://speedrent.in/v2Apis/production/Bookings/getAvailableBikesWithPrices.php",
          new URLSearchParams({
            api_key: "Pavan@2024",
            pickupDate,
            dropDate,
            customerId: "",
            branch,
          })
        );

        let bikesData = response.data.data || [];

        // Apply Transmission Type Filter
        if (filterByTransmission && filterByTransmission !== "All") {
          bikesData = bikesData.filter(
            (bike) =>
              bike.transmission.trim().toLowerCase() ===
              filterByTransmission.toLowerCase()
          );
        }

        // Apply Fuel Type Filter
        if (filterByFuelType && filterByFuelType !== "All") {
          bikesData = bikesData.filter(
            (bike) =>
              bike.fuel.trim().toLowerCase() === filterByFuelType.toLowerCase()
          );
        }

        // Apply Price Sorting
        if (sortByPrice === "1") {
          bikesData.sort((a, b) => a.booking_amount - b.booking_amount);
        } else if (sortByPrice === "2") {
          bikesData.sort((a, b) => b.booking_amount - a.booking_amount);
        }

        setBikes(bikesData);
      } catch (error) {
        console.error("Error fetching bikes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBikes();
  }, [
    pickupDate,
    dropDate,
    branch,
    sortByPrice,
    filterByTransmission,
    filterByFuelType,
  ]);

  const handleLoadMore = () => {
    setDisplayedBikes((prevDisplayedBikes) => prevDisplayedBikes + 9);
  };

  const handleShowModal = (bike) => {
    setSelectedBike(bike);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedBike(null);
  };

  return (
    <>
      <div className="d-flex">
        <div className="m-auto">
          <Container className="my-4">
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <Row>
                {bikes.length > 0 ? (
                  bikes.slice(0, displayedBikes).map((bike) => (
                    <Col xs={12} md={6} lg={4} key={bike.id} className="mb-3">
                      <Card
                        className="bike-card shadow-sm border cardlistbikehoveffect"
                        onClick={() => handleShowModal(bike)}
                        style={{ cursor: "pointer" }}
                      >
                        <Row className="g-0">
                          <Col xs={6} className="bike-image-col shadow-sm">
                            <Card.Img
                              className="bike-image-list"
                              src={`https://speedrent.in/adminapp/uploads/${bike.modelId}.png`}
                              alt={bike.modelName}
                            />
                          </Col>
                          <Col xs={6}>
                            <Card.Body className="text-start py-2">
                              <span className="bike-list-modal-name p-0 m-0">
                                {bike.modelCompany}
                              </span>
                              <Card.Title className="p-0 m-0">
                                {bike.modelName}
                              </Card.Title>
                              <Card.Text>
                                <div className="tittletxtovrflq">
                                  <span className="bikepricespan">
                                    ₹ {bike.booking_amount}
                                  </span>
                                  <span style={{ fontSize: "small" }}>
                                    - Estimated Fare
                                  </span>
                                </div>
                                <span className="branchfuelspan">
                                  <i className="fa-solid fa-location-dot me-1 fa-sm"></i>
                                  {branch}
                                  <br />
                                  <i
                                    class="fa-solid fa-circle-check me-1 fa-sm"
                                    style={{ color: "green" }}
                                  ></i>
                                  {bike.availableCount}
                                  <span style={{ fontSize: "smaller" }}>
                                    (Available)
                                  </span>
                                  {/* <i className="fa-solid fa-gas-pump me-1 fa-sm"></i>
                                  {bike.fuel} */}
                                </span>
                                <button className="w-100 rounded book-now-bike-btn shadow-sm border mt-1">
                                  Book Now
                                </button>
                              </Card.Text>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  ))
                ) : (
                  <p>No bikes available at the moment.</p>
                )}
              </Row>
            )}
            {!loading && displayedBikes < bikes.length && (
              <div className="text-center my-2">
                <button
                  onClick={handleLoadMore}
                  className="load-more-bikes-btn px-3 py-1"
                >
                  <u>Load More</u>
                  <i className="fa-solid fa-motorcycle ms-2"></i>
                </button>
              </div>
            )}
            {!loading && displayedBikes >= bikes.length && (
              <div className="text-center">
                ______
                <i className="fa-solid fa-motorcycle ms-2"></i>
                &nbsp;______
              </div>
            )}
          </Container>
        </div>
      </div>

      {selectedBike && (
        <BikeDetailsModal
          show={showModal}
          onHide={handleCloseModal}
          bike={selectedBike}
          duration={duration}
          branch={branch}
          pickupDate={pickupDate}
          dropDate={dropDate}
        />
      )}
    </>
  );
}
