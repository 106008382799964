import React from "react";

const BikeImage = ({ imageUrl, isZoomed, onZoomToggle }) => (
  <div className="image-container-modal py-2 pb-3 position-relative">
    <img
      className={`bike-image img-fluid rounded-4 ${isZoomed ? "zoomed" : ""}`}
      src={imageUrl}
      alt="Bike Image"
    />
    <button
      className="btn btn-outline-light position-absolute bottom-0 end-0 m-2 border"
      onClick={onZoomToggle}
    >
      <i className={`fa-solid ${isZoomed ? "fa-compress" : "fa-expand"}`}></i>
    </button>
  </div>
);

export default BikeImage;
